import { throttle, debounce } from "throttle-debounce";
import React, { Component, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { withContext } from './App';
import { withI18n } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { localModel } from './localModel';
import AddIcon from '@material-ui/icons/Add';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import ZoomToFitIcon from '@material-ui/icons/ZoomOutMap';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import history from './history';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import AutoComplete from './AutoComplete';

import BigCalendar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import 'moment/locale/es';

import ListIcon from '@material-ui/icons/ViewList';
import EventIcon from '@material-ui/icons/Event';
import MapIcon from '@material-ui/icons/LocationOn';
import GalleryIcon from '@material-ui/icons/ViewModule';
import DiagramIcon from '@material-ui/icons/DeviceHub';

import FilterListIcon from '@material-ui/icons/FilterList';
import MemoryIcon from '@material-ui/icons/Memory';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { Map, Marker, TileLayer, ImageOverlay } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

import FormControlLabel from '@material-ui/core/FormControlLabel';

// BUG https://github.com/Leaflet/Leaflet/issues/4968
import L from 'leaflet';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'
import { refreshPersonalization, refreshModels } from './refresh';

import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import Barcode from './Barcode';

import createEngine, { 
    DefaultLinkModel, 
    DefaultNodeModel,
    DiagramModel,
    DagreEngine,
} from '@projectstorm/react-diagrams';

import {
    CanvasWidget
} from '@projectstorm/react-canvas-core';

import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import CloseIcon from '@material-ui/icons/Close';

import { AdvancedLinkFactory, AdvancedPortModel } from "./ArrowLink";

let idByName = {};

const engine = createEngine();
engine.getLinkFactories().registerFactory(new AdvancedLinkFactory());

L.Marker.prototype.options.icon = L.icon({
	iconRetinaUrl,
	iconUrl,
	shadowUrl,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	tooltipAnchor: [16, -28],
	shadowSize: [41, 41]
});

const localizer = BigCalendar.momentLocalizer(moment);

const styles = theme => ({
	spacer: {
		flex: "1 1 1%",
	},
	paper: {
		padding: theme.spacing.unit * 2,
		[theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
			padding: theme.spacing.unit * 3,
		},
	},
	paperBottom: {
		padding: theme.spacing.unit * 2,
		[theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
			padding: theme.spacing.unit * 3,
		},
		marginBottom: theme.spacing.unit * 2,
	},
	checkbox: {
		width: "1px",
	},
	fab: {
		position: 'fixed',
		zIndex: 1000,
		bottom: theme.spacing.unit * 2,
		right: theme.spacing.unit * 2,
	},
	fab2: {
		position: 'fixed',
		zIndex: 1000,
		bottom: theme.spacing.unit * 2 + 64,
		right: theme.spacing.unit * 2,
	},
	root: {
		width: '100%',
		overflowX: 'auto',
		overflowY: 'auto',
	},
	canvasWidget: {
		minHeight: "100vh",
	},
	table: {
		minWidth: 700,
	},
	tableHeader: {
		whiteSpace: "nowrap",
	},
	tableRow: {
		cursor: "pointer",
	},
	toolbarTitle: {
		flex: 1,
		marginBottom: theme.spacing.unit * 2,
	},
	toolbar: {
	},
	toolbar2: {
		color: theme.palette.secondary.contrastText,
		backgroundColor: theme.palette.secondary.main,
	},
	actionToolbar: {
		flex: "0 0 auto",
	},
	action: {
		color: theme.palette.secondary.contrastText,
		"&$disabledAction": {
			color: theme.palette.secondary.light,
		}
	},
	expandMoreButton: {
		width: "100%",
	},
	disabledAction: {
	},
	noData: {
		flex: 1,
		margin: theme.spacing.unit * 2,
	},
	input: {
		margin: theme.spacing.unit,
	},
	snackbar: {
	},
	snackbarError: {
		backgroundColor: theme.palette.error.dark,
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		fontSize: 20,
		marginRight: theme.spacing.unit,
	},
	anchor: {
		color: theme.palette.text.primary,
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		marginRight: theme.spacing.unit * 2,
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing.unit * 3,
			width: 'auto',
		},		
	},
	toggleContainer: {
		display: "inline-flex",
		margin: theme.spacing.unit,
	},
	calendar: {
		height: "700px",
	},
	bigCalendar: {
		padding: theme.spacing.unit * 3,
		fontFamily: "Roboto, Helvetica, Arial, sans-serif",
	},
	intelligenceCell: {
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.main,
		opacity: "0.9",
		border: "0",
	},
	previewDialogPaper: {
		minHeight: "95vh",
		maxHeight: "95vh",
		minWidth: "95vw",
		maxWidth: "95vw",
	},
	dialogContent: {
		display: "flex",
		flexGrow: "1",
		paddingTop: "16px",
	},
	docViewer: {
		display: "flex",
		flexGrow: "1",
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing.unit,
		top: theme.spacing.unit,
	},
	iframe: {
		width: '100%',
		minHeight: "300px",
		border: "none",
		// overflow: hidden - Es imposible que un iframe muestre algo flotante sobre otro (sería un problema de seguridad)
		// Otra cosa es que se coja el contenido del iframe y se inyecte en un div, con javascript.
	},
});

const BlockPageScroll = ({ children }) => {
	const scrollRef = useRef(null)
	useEffect(() => {
		const scrollEl = scrollRef.current
		scrollEl.addEventListener('wheel', stopScroll)
	    return () => scrollEl.removeEventListener('wheel', stopScroll)
	}, [])
	const stopScroll = e => e.preventDefault()
	return (
			<div ref={scrollRef}>
				{children}
			</div>
	)
}

class EntityList extends Component {
	
	dagreEngine: DagreEngine;
	
	constructor(props) {
		super(props);
		// console.log(">> EntityList.constructor");
		
		this.dagreEngine = new DagreEngine({
			graph: {
				rankdir: "LR", // T=top, B=bottom, L=left, R=right (TB, BT, LR, RL)
				ranker: "network-simplex", // network-simplex, tight-tree, longest-path
				marginx: 25,
				marginy: 25,
				nodesep: 50,
				edgesep: 10,
				ranksep: 50,
				acyclicer: undefined, // "greedy",
			},
			includeLinks: true
		});		
		
		let entityLocalModel = localModel.entities[props.entity]
		let localAttributes = Object.values(entityLocalModel.attributes);
		
		if (this.props.context.state[this.props.entity] == null) {
			this.props.context.state[this.props.entity] = {};
		}
		if (this.props.context.state[this.props.entity].mode == null) {
			this.props.context.state[this.props.entity].mode = (localAttributes.filter(attribute => attribute.imageInGallery).length > 0 ? "gallery" : (localAttributes.filter(attribute => attribute.pointInMap).length > 0 ? "table" : "table"));
		}
		if (this.props.context.state[this.props.entity].basicFiltersEnabled == null) {
			this.props.context.state[this.props.entity].basicFiltersEnabled = false;
		}
		if (this.props.context.state[this.props.entity].basicFilters == null) {
			this.props.context.state[this.props.entity].basicFilters = {};
		}
		if (this.props.context.state[this.props.entity].orderBy == null) {
			this.props.context.state[this.props.entity].orderBy = null;		
		}
		if (this.props.context.state[this.props.entity].orderDirection == null) {
			this.props.context.state[this.props.entity].orderDirection = "asc";		
		}
		
		Object.keys(entityLocalModel.attributes)
				.filter(attributeName => entityLocalModel.attributes[attributeName].filteredWhenEmpty)
				.forEach(attributeName => {
					if (this.props.context.state[this.props.entity].basicFilters[attributeName] == null) {
						this.props.context.state[this.props.entity].basicFilters[attributeName] = true;
					}
				});
		
		this.state = {
			currentEntity: null,
			data: null,
			deleteConfirmationDialogOpened: false,
			intelligenceEnabled: false,
			scrollEnabled: true,
			limit: 30,
			latitude: 40.529179,
			longitude: -3.651276,
			previewOpen: false,
		};
		
		this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
		this.handleAnchorClick = this.handleAnchorClick.bind(this);
		this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
		this.handleRowClick = this.handleRowClick.bind(this);
		this.handleCellClick = this.handleCellClick.bind(this);
		this.handleNewClick = this.handleNewClick.bind(this);
		this.handleViewClick = this.handleViewClick.bind(this);
		this.handleDownloadClick = this.handleDownloadClick.bind(this);
		this.handleEditClick = this.handleEditClick.bind(this);
		this.handleDeleteClick = this.handleDeleteClick.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleSortClick = this.handleSortClick.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.handleSearchKeyDown = this.handleSearchKeyDown.bind(this);
		this.handleSearchClick = this.handleSearchClick.bind(this);
		this.handleClearCriteriaClick = this.handleClearCriteriaClick.bind(this);
		
		this.handleChangeMode = this.handleChangeMode.bind(this);
		this.handleToggleBasicFilters = this.handleToggleBasicFilters.bind(this);
		this.handleToggleIntelligence = this.handleToggleIntelligence.bind(this);
		
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		this.handleBasicFilterCheckboxChange = this.handleBasicFilterCheckboxChange.bind(this);
		
		this.handleDiagramEvent = this.handleDiagramEvent.bind(this);
		
		this.expandMore = this.expandMore.bind(this);
		
		this.refreshDataThrottled = throttle(500, this.refreshData);
		this.handleDeleteDebounced = debounce(3000, true, this.handleDelete);
		
		this.searchInput = React.createRef();
		
		// TODO Habrá que poner una propiedad enableScroll para habilitar esto,
		// en los detalles estará deshabilitado.
		// TODO También habrá un botón que podrás pulsar o que saldrá después de
		// varios scrolls (como en twitter).
		if (this.props.main) {
			window.addEventListener("scroll", this.updateDimensions.bind(this));
		}
	}
	
	// Event handlers
	updateDimensions(event) {
		if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 1) {
			if (this.state.scrollEnabled && this.props.context.state[this.props.entity] != null && (this.props.context.state[this.props.entity].mode == 'table' || this.props.context.state[this.props.entity].mode == 'gallery')) {
				if (this._isMounted) {
					this.setState((state, props) => ({
						limit: Math.min(1000, state.limit + 30),
					}), () => this.refreshDataThrottled());
				}
			}
		}
	}
	
	expandMore() {
		if (this.state.scrollEnabled && (this.props.context.state[this.props.entity].mode == 'table' || this.props.context.state[this.props.entity].mode == 'gallery')) {
			if (this._isMounted) {
				this.setState((state, props) => ({
					limit: Math.min(1000, state.limit + 30),
				}), () => this.refreshDataThrottled());
			}
		}
	}
	
	handleToggleBasicFilters(event, mode) {
		this.props.context.state[this.props.entity].basicFiltersEnabled = mode;
		
		if (!mode) {
			let attributes = this.state.attributes;
			const entityLocalModel = localModel.entities[this.props.entity];
			const model = this.props.context.model;
			
			attributes
					.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].basicFilter
							|| entityLocalModel.attributes[attribute.referenceAttributeName] != null && entityLocalModel.attributes[attribute.referenceAttributeName].basicFilter)
					.forEach(attribute => {
				
				if (!attribute.array
						&& attribute.referenceAttributeName !== undefined) {
					if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
						let subKeyAttribute = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0];
						this.basicFilterRefs[attribute.name].current.select.select.setValue(null, 'select-option');
						this.props.context.state[this.props.entity].basicFilters[attribute.name] = "";
					}
				}
				else if (attribute.enumType === undefined && (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")) {
					if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
						this.basicFilterRefs[attribute.name].current.value = "";
						this.props.context.state[this.props.entity].basicFilters[attribute.name] = "";
					}
				}
				else if (attribute.enumType !== undefined && (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")) {
					if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
						Object.values(this.basicFilterRefs[attribute.name].current.options).forEach(option => option.selected = option.value == "");
						this.props.context.state[this.props.entity].basicFilters[attribute.name] = null;
					}
				}
				else if (!attribute.array
						&& (attribute.type === "INTEGER"
							|| attribute.type === "SMALLINT"
							|| attribute.type === "BIGINT"
							|| attribute.type === "SERIAL"
							|| attribute.type === "DECIMAL"
							|| attribute.type === "DOUBLE_PRECISION"
							|| attribute.type === "REAL"
							|| attribute.type === "MONEY"
							|| attribute.type === "SMALLSERIAL"
							|| attribute.type === "BIGSERIAL")) {
					if (this.basicFilterRefs[attribute.name + "From"] != null && this.basicFilterRefs[attribute.name + "From"].current != null) {
						this.basicFilterRefs[attribute.name + "From"].current.value = "";
						this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"] = "";
					}
					if (this.basicFilterRefs[attribute.name + "To"] != null && this.basicFilterRefs[attribute.name + "To"].current != null) {
						this.basicFilterRefs[attribute.name + "To"].current.value = "";
						this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"] = "";
					}
				}
				else if (!attribute.array
						&& (attribute.type === "DATE" || attribute.type === "TIMESTAMP" || attribute.type === "TIME")) {
					if (this.basicFilterRefs[attribute.name + "From"] != null && this.basicFilterRefs[attribute.name + "From"].current != null) {
						this.basicFilterRefs[attribute.name + "From"].current.value = "";
						this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"] = "";
					}
					if (this.basicFilterRefs[attribute.name + "To"] != null && this.basicFilterRefs[attribute.name + "To"].current != null) {
						this.basicFilterRefs[attribute.name + "To"].current.value = "";
						this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"] = "";
					}
				}
				else if (!attribute.array && attribute.type == "BOOLEAN") {
					this.props.context.state[this.props.entity].basicFilters[attribute.name] = null;
				}
			});
			
			this.setState({
				// No se utiliza, pero sirve para forzar el refresco de la pantalla
				basicFiltersEnabled: mode,
				limit: 30,
			}, () => this.refreshDataThrottled());
		}
		else {
			this.setState({
				// No se utiliza, pero sirve para forzar el refresco de la pantalla
				basicFiltersEnabled: mode,
			});
		}
	}
	
	handleToggleIntelligence(event, mode) {
		this.setState({
			intelligenceEnabled: mode,
		});
	}
	
	handleCheckboxChange(event, checked, attributeName) {
		//console.log(">> EntityView.handleCheckboxChange")
		this.setState((state, props) => {
			this.props.context.state[this.props.entity].basicFilters[attributeName] = checked;
			return {
				// No se utiliza, pero sirve para forzar el refresco de la pantalla
				basicFilters: this.props.context.state[this.props.entity].basicFilters,
			};
		},  () => this.handleSearchClick(event));
	}
	
	handleBasicFilterCheckboxChange(attributeName) {
		//console.log(">> EntityView.handleBasicFilterCheckboxChange")
		if (this._isMounted) {
			this.setState((state, props) => {
				this.props.context.state[this.props.entity].basicFilters[attributeName] = (this.props.context.state[this.props.entity].basicFilters[attributeName] == null ? true : (this.props.context.state[this.props.entity].basicFilters[attributeName] ? false : null))
				return {
					// No se utiliza, pero sirve para forzar el refresco de la pantalla
					basicFilters: this.props.context.state[this.props.entity].basicFilters,
				};
			});
		}
	}
	
	handleChangeMode(event, mode) {
		if (mode != null) {
			this.props.context.state[this.props.entity].mode = mode;
			this.setState({
				// No se utiliza, pero sirve para forzar el refresco de la pantalla
				mode: mode
			});
		}
	}
	
	handleSearchKeyDown(event) {
		if (event.keyCode === 13) {
			this.props.context.state[this.props.entity].searchCriteria = this.searchInput.current.value;
			this.setState({
				limit: 30,
			}, () => this.refreshDataThrottled());
		}
	}
	
	handleSearchClick(event) {
		this.setState({
			limit: 30,
		}, () => this.refreshDataThrottled());
	}
	
	handleClearCriteriaClick(event) {
		let attributes = this.state.attributes;
		const entityLocalModel = localModel.entities[this.props.entity];
		const model = this.props.context.model;
		
		if (this.searchInput.current != null) {
			this.searchInput.current.value = "";
			this.props.context.state[this.props.entity].searchCriteria = this.searchInput.current.value;
		}
		
		attributes
				.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].basicFilter
						|| entityLocalModel.attributes[attribute.referenceAttributeName] != null && entityLocalModel.attributes[attribute.referenceAttributeName].basicFilter)
				.forEach(attribute => {
			
			if (!attribute.array
					&& attribute.referenceAttributeName !== undefined) {
				if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
					let subKeyAttribute = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0];
					this.basicFilterRefs[attribute.name].current.select.select.setValue(null, 'select-option');
					this.props.context.state[this.props.entity].basicFilters[attribute.name] = "";
				}
			}
			else if (attribute.enumType === undefined && (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")) {
				if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
					this.basicFilterRefs[attribute.name].current.value = "";
					this.props.context.state[this.props.entity].basicFilters[attribute.name] = "";
				}
			}
			else if (attribute.enumType !== undefined && (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")) {
				if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
					Object.values(this.basicFilterRefs[attribute.name].current.options).forEach(option => option.selected = option.value == "");
					this.props.context.state[this.props.entity].basicFilters[attribute.name] = null;
				}
			}
			else if (!attribute.array
					&& (attribute.type === "INTEGER"
						|| attribute.type === "SMALLINT"
						|| attribute.type === "BIGINT"
						|| attribute.type === "SERIAL"
						|| attribute.type === "DECIMAL"
						|| attribute.type === "DOUBLE_PRECISION"
						|| attribute.type === "REAL"
						|| attribute.type === "MONEY"
						|| attribute.type === "SMALLSERIAL"
						|| attribute.type === "BIGSERIAL")) {
				if (this.basicFilterRefs[attribute.name + "From"] != null && this.basicFilterRefs[attribute.name + "From"].current != null) {
					this.basicFilterRefs[attribute.name + "From"].current.value = "";
					this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"] = "";
				}
				if (this.basicFilterRefs[attribute.name + "To"] != null && this.basicFilterRefs[attribute.name + "To"].current != null) {
					this.basicFilterRefs[attribute.name + "To"].current.value = "";
					this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"] = "";
				}
			}
			else if (!attribute.array
					&& (attribute.type === "DATE" || attribute.type === "TIMESTAMP" || attribute.type === "TIME")) {
				if (this.basicFilterRefs[attribute.name + "From"] != null && this.basicFilterRefs[attribute.name + "From"].current != null) {
					this.basicFilterRefs[attribute.name + "From"].current.value = "";
					this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"] = "";
				}
				if (this.basicFilterRefs[attribute.name + "To"] != null && this.basicFilterRefs[attribute.name + "To"].current != null) {
					this.basicFilterRefs[attribute.name + "To"].current.value = "";
					this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"] = "";
				}
			}
			else if (!attribute.array && attribute.type == "BOOLEAN") {
				this.props.context.state[this.props.entity].basicFilters[attribute.name] = null;
			}
		});
		
		this.setState({
			limit: 30,
		}, () => this.refreshDataThrottled());
	}
	
	handleSelectAllClick(event, checked) {
		this.state.data.map(item => item._selected = checked);
		this.setState((state, props) => ({data: state.data}));
	}
	
	go(target, resetTab, entity) {
		if (resetTab) {
			let entityLocalModel = localModel.entities[entity]
			let selectedTab = null;
			if (entityLocalModel.tabs != null) {
				let selectedTabItem = Object.values(entityLocalModel.tabs).filter(tab => Object.values(entityLocalModel.attributes).filter(attribute => attribute.tab === tab.id).length > 0).sort((a, b) => a.order - b.order)[0];
				if (selectedTabItem != null) {
					selectedTab = selectedTabItem.id;
				}
			}
			if (this.props.context.state[entity] == null) {
				this.props.context.state[entity] = {};
			}
			this.props.context.state[entity].selectedTab = selectedTab;
		}
		
		history.push(target);
	}
	
	handleRowClick(event, item) {
		event.stopPropagation();
		this.go("/admin/" + this.props.entity + '/' + item[this.state.keyAttribute.name] + "/view", true, this.props.entity);
	}
	
	handleCellClick(event, item, attribute, entityModel, entityLocalModel) {
		if (entityLocalModel.attributes[attribute.name].type == "DOCUMENT"
				&& item[attribute.name] != null) {
			if (item[attribute.name].type == "application/pdf") {
				event.stopPropagation();
				window.open(this.props.context.baseUrl + "/wopi/files/" + entityModel.schema + "/" + entityModel.name + "/" + item[this.state.keyAttribute.name] + "/" + attribute.name + "/contents?inline=true&access_token=" + this.props.context.accessToken);
			}
			else if (item[attribute.name].type == ""
					|| item[attribute.name].type == "image/bmp"
					|| item[attribute.name].type == "application/msword"
					|| item[attribute.name].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
					|| item[attribute.name].type == "text/htm"
					|| item[attribute.name].type == "text/html"
					|| item[attribute.name].type == "image/jpg"
					|| item[attribute.name].type == "image/jpeg"
				//	|| item[attribute.name].type == "application/pdf"
					|| item[attribute.name].type == "image/png"
					|| item[attribute.name].type == "application/vnd.ms-powerpoint"
					|| item[attribute.name].type == "application/vnd.openxmlformats-officedocument.presentationml.presentation"
				//	|| item[attribute.name].type == "image/tiff" - No funciona
					|| item[attribute.name].type == "application/vnd.ms-excel"
					|| item[attribute.name].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
				event.stopPropagation();
				this.setState({
					previewOpen: true,
					previewUrl: this.props.context.baseUrl + "/wopi/files/" + entityModel.schema + "/" + entityModel.name + "/" + item[this.state.keyAttribute.name] + "/" + attribute.name + "/contents?inline=true&access_token=" + this.props.context.accessToken,
				});
			}
		}
	}
	
	onPreviewCloseDialog = () => {
		this.setState({
			previewOpen: false,
		});
	};
	
	handleCheckboxClick(event, item) {
		event.stopPropagation();
		item._selected = !item._selected;
		this.setState((state, props) => ({
			data: state.data,
		}));
	}
	
	handleAnchorClick(event, item, attribute) {
		event.preventDefault();
		event.stopPropagation();
		const model = this.props.context.model;
		let keyAttribute = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0];
		this.go("/admin/" + attribute.referencedKey.entityName + "/" + item[attribute.referenceAttributeName][keyAttribute.name] + "/view", true, attribute.referencedKey.entityName);
	}
	
	handleNewClick(event) {
		if (this.props.filterAttribute == null) {
			this.go("/admin/" + this.props.entity + "/new", true, this.props.entity);
		}
		else {
			this.go("/admin/" + this.props.entity + "/new/" + this.props.filterAttribute + "/" + this.props.filterValue, true, this.props.entity);
		}
	}
	
	handleViewClick(event) {
		this.go("/admin/" + this.props.entity + '/' + this.state.data.filter(item => item._selected)[0][this.state.keyAttribute.name] + "/view", true, this.props.entity);
	}
	
	handleEditClick(event) {
		this.go("/admin/" + this.props.entity + '/' + this.state.data.filter(item => item._selected)[0][this.state.keyAttribute.name] + "/edit", true, this.props.entity);
	}
	
	handleDeleteClick(event) {
		this.setState({
			deleteConfirmationDialogOpened: true,
		});
	}
	
	handleDownloadClick(event) {
		this.props.context.showActivityIndicator();
		let filename = null;
		
		const selectedItems = this.state.data.filter(item => item._selected);
		
		fetch(this.props.context.baseUrl + "/data-export?entity=" + this.props.entity + "&" + this.state.keyAttribute.name + "=" + selectedItems.map(item => this.setQuotes(this.state.keyAttribute, item[this.state.keyAttribute.name])).toString(), {
			method: "GET",
			headers: {
				"Authorization": "Basic " + this.props.context.accessToken,
			},
		})
		.then(response => {
			filename = response.headers.get("Content-Disposition").split(";")[1].split("=")[1].slice(1, -1);
			return response.blob();
		})
		.then(blob => {
			var url = window.URL.createObjectURL(blob);
			var a = document.createElement('a');
			a.href = url;
			a.download = filename;
			document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
			a.click();    
			a.remove();  //afterwards we remove the element again         
			this.props.context.hideActivityIndicator();
		});
	}
	
	handleDelete(event) {
		this.props.context.showActivityIndicator();
		const selectedItems = this.state.data.filter(item => item._selected);
		
		let query = 'mutation { ' + this.props.entity.replace(".", "_") + 'Delete( where: { ' + this.state.keyAttribute.name + ': { IN: [' 
				+ selectedItems.map(item => this.setQuotes(this.state.keyAttribute, item[this.state.keyAttribute.name])).toString() 
				+ '] }}) { ' + this.state.keyAttribute.name + ' }}';
		
		const variables = {
    		authorization: this.props.context.accessToken
    	};
    	
		// console.log("Query: " + query);
		
		let request = JSON.stringify({query: query, variables: variables});
		fetch(this.props.context.baseUrl + "/graphql", {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			if (json != null
					&& json.errors != null
					&& json.errors[0] != null
					&& json.errors[0].message == "SessionTimeout") {
				document.location = '/login';
			}
			
			// This is considered an anti-pattern
			if (this._isMounted) {
				if (json.errors != null) {
					this.setState({
						message: json.errors[0].message,
						messageError: true,
						messageOpened: true,
						deleteConfirmationDialogOpened: false,
					}, () => this.refreshDataThrottled());
				}
				else {
					if (this.props.entity == 'Models.Personalization') {
						refreshPersonalization(this.props.theme, this.props.context.baseUrl).then(result => {
							this.forceUpdate();
							this.setState({
								message: selectedItems.length + " " + this.props.t('deleteSuccess'),
								messageError: false,
								messageOpened: true,
								deleteConfirmationDialogOpened: false,
							}, () => this.refreshDataThrottled());
						});
					}
					else if (this.props.entity.startsWith("Models.")) {
						refreshModels(this.props.context.accessToken, this.props.context.username, this.props.context).then(result => {
							this.forceUpdate();
							this.setState({
								message: selectedItems.length + " " + this.props.t('deleteSuccess'),
								messageError: false,
								messageOpened: true,
								deleteConfirmationDialogOpened: false,
							}, () => this.refreshDataThrottled());
						});
					}
					else {
						this.setState({
							message: selectedItems.length + " " + this.props.t('deleteSuccess'),
							messageError: false,
							messageOpened: true,
							deleteConfirmationDialogOpened: false,
						}, () => this.refreshDataThrottled());
					}
				}
			}
			else {
				this.props.context.hideActivityIndicator();
			}
		});
	}
	
	handleSortClick(event, columnName) {
		if (this.props.context.state[this.props.entity].orderBy !== columnName) {
			this.props.context.state[this.props.entity].orderBy = columnName;
			this.props.context.state[this.props.entity].orderDirection = "asc";
			this.setState({
				// No se utiliza, pero sirve para forzar el refresco de la pantalla
				orderBy: columnName,
				orderDirection: 'asc',
			}, () => this.refreshDataThrottled());
		}
		else {
			this.props.context.state[this.props.entity].orderBy = columnName;
			this.props.context.state[this.props.entity].orderDirection = (this.props.context.state[this.props.entity].orderDirection === 'asc' ? 'desc' : 'asc');
			this.setState((state, props) => ({
				orderBy: columnName,
				orderDirection: (this.props.context.state[this.props.entity].orderDirection === 'asc' ? 'desc' : 'asc'),
			}), () => this.refreshDataThrottled());
		}
	}
	
	handleDiagramEvent(event) {
		// Mostrar / Ocultar barra de edición
		if (event.function == "selectionChanged" && event.entity instanceof DefaultNodeModel) {
			let entityName = event.entity.options.name;
			let schema = entityName.split(".")[0];
			let name = entityName.split(".")[1];
			let items = this.state.data.filter(item => item.schema == schema && item.name == name);
			if (items != null && items[0] != null) {
				items[0]._selected = event.isSelected;
				this.setState((state, props) => ({
					data: this.state.data,
				}));
			}
		}
		
		// Crear referencia
		else if (event.function == "linksUpdated" && event.isCreated) {
			let link = event.link;
			link.registerListener({
				eventDidFire: this.handleDiagramEvent
			});
		}
		
		// Terminar de enlazar la referencia creada
		else if (event.function == "targetPortChanged") {
			this.props.context.showActivityIndicator();
			
			let sourceEntity = event.entity.sourcePort.parent.options.name;
			let targetEntity = event.entity.targetPort.parent.options.name;
			
			let sourceEntitySchema = sourceEntity.split(".")[0];
			let sourceEntityName = sourceEntity.split(".")[1];
			let targetEntitySchema = targetEntity.split(".")[0];
			let targetEntityName = targetEntity.split(".")[1];
			let referenceName = event.entity.sourcePort.options.name.split(" ")[1];
			
			let query = "{container: Models_EntityList(where: {AND: [{schema: {EQ: \"" + sourceEntitySchema + "\"}} {name: {EQ: \"" + sourceEntityName + "\"}}]}) {id} referencedKey: Models_EntityKeyList(where: {isPrimaryKey: {EQ: true}}) {id container: EntityViaContainer(joinType: INNER where: {AND: [{schema: {EQ: \"" + targetEntitySchema + "\"}} {name: {EQ: \"" + targetEntityName + "\"}}]}) {id}}}";
			const variables = {
	    		authorization: this.props.context.accessToken
	    	};
			let request = JSON.stringify({query: query, variables: variables});
			fetch(this.props.context.baseUrl + "/graphql", {
				method: "POST",
				body: request
			})
			.then(response => response.json())
			.then(json => {
				if (json != null
						&& json.errors != null
						&& json.errors[0] != null
						&& json.errors[0].message == "SessionTimeout") {
					document.location = '/login';
				}
				
				let containerId = json.data.container[0].id;
				let referencedKeyId = json.data.referencedKey[0].id;
				
				query = "mutation {Models_EntityReferenceCreate(entity: {container: " + containerId + " referencedKey: " + referencedKeyId + " name: \"" + referenceName + "\" isBasicFilter: true isCascadeDelete: true isList: true isVisible: true listIsVisible: true}) {id}}";
				let request = JSON.stringify({query: query, variables: variables});
				fetch(this.props.context.baseUrl + "/graphql", {
					method: "POST",
					body: request
				})
				.then(response => response.json())
				.then(json => {
					if (json != null
							&& json.errors != null
							&& json.errors[0] != null
							&& json.errors[0].message == "SessionTimeout") {
						document.location = '/login';
					}
					
					refreshModels(this.props.context.accessToken, this.props.context.username, this.props.context).then(result => {
						this.setState({
							message: this.props.t('referenceCreated'),
							messageError: false,
							messageOpened: true,
						}, () => setTimeout(() => this.refreshDataThrottled(), 500));
					});
				});
			});
		}
		
		// Borrar entidad
		else if (event.function == "entityRemoved" && event.entity instanceof DefaultNodeModel) {
			//console.log("Borrar entidad");
		}
		
		// Borrar referencia
		else if (event.function == "entityRemoved" && event.entity instanceof DefaultLinkModel) {
			this.props.context.showActivityIndicator();
			
			let sourceEntity = event.entity.sourcePort.parent.options.name;
			
			let sourceEntitySchema = sourceEntity.split(".")[0];
			let sourceEntityName = sourceEntity.split(".")[1];
			let referenceName = event.entity.sourcePort.options.name.split(" ")[1];
			
			let query = "{container: Models_EntityList(where: {AND: [{schema: {EQ: \"" + sourceEntitySchema + "\"}} {name: {EQ: \"" + sourceEntityName + "\"}}]}) {id}}";
			const variables = {
	    		authorization: this.props.context.accessToken
	    	};
			let request = JSON.stringify({query: query, variables: variables});
			fetch(this.props.context.baseUrl + "/graphql", {
				method: "POST",
				body: request
			})
			.then(response => response.json())
			.then(json => {
				if (json != null
						&& json.errors != null
						&& json.errors[0] != null
						&& json.errors[0].message == "SessionTimeout") {
					document.location = '/login';
				}
				
				let containerId = json.data.container[0].id;
				
				query = "mutation {Models_EntityReferenceDelete(where: {AND: [{container: {EQ: " + containerId + "}} {name: {EQ: \"" + referenceName + "\"}}]}) {id}}";
				let request = JSON.stringify({query: query, variables: variables});
				fetch(this.props.context.baseUrl + "/graphql", {
					method: "POST",
					body: request
				})
				.then(response => response.json())
				.then(json => {
					if (json != null
							&& json.errors != null
							&& json.errors[0] != null
							&& json.errors[0].message == "SessionTimeout") {
						document.location = '/login';
					}
					
					refreshModels(this.props.context.accessToken, this.props.context.username, this.props.context).then(result => {
						this.setState({
							message: this.props.t('referenceDeleted'),
							messageError: false,
							messageOpened: true,
						}, () => setTimeout(() => this.refreshDataThrottled(), 500));
					});
				});
			});
		}
	}
	
	// Life cycle methods
	
	componentDidMount() {
		// console.log(">> EntityList.componentDidMount");
		this.props.context.handleDrawerClose();
		this._isMounted = true;
		this.refreshDataThrottled();
	}
	
	componentDidUpdate(prevProps) {
		// console.log(">> EntityList.componentDidUpdate");
		if (this.props.entity !== prevProps.entity
				|| this.props.filterAttribute !== prevProps.filterAttribute
				|| this.props.filterValue !== prevProps.filterValue) {
			this.props.context.handleDrawerClose();
			window.scrollTo(0, 0);
			let entityLocalModel = localModel.entities[this.props.entity];
			let localAttributes = Object.values(entityLocalModel.attributes);
			
			if (this.props.context.state[this.props.entity] == null) {
				this.props.context.state[this.props.entity] = {};
			}
			if (this.props.context.state[this.props.entity].mode == null) {
				this.props.context.state[this.props.entity].mode = (localAttributes.filter(attribute => attribute.imageInGallery).length > 0 ? "gallery" : (localAttributes.filter(attribute => attribute.pointInMap).length > 0 ? "table" : "table"));
			}
			if (this.props.context.state[this.props.entity].basicFiltersEnabled == null) {
				this.props.context.state[this.props.entity].basicFiltersEnabled = false;
			}
			if (this.props.context.state[this.props.entity].basicFilters == null) {
				this.props.context.state[this.props.entity].basicFilters = {};
			}
			if (this.props.context.state[this.props.entity].orderBy == null) {
				this.props.context.state[this.props.entity].orderBy = null;
			}
			if (this.props.context.state[this.props.entity].orderDirection == null) {
				this.props.context.state[this.props.entity].orderDirection = "asc";
			}
			Object.keys(entityLocalModel.attributes)
					.filter(attributeName => entityLocalModel.attributes[attributeName].filteredWhenEmpty)
					.forEach(attributeName => {
						if (this.props.context.state[this.props.entity].basicFilters[attributeName] == null) {
							this.props.context.state[this.props.entity].basicFilters[attributeName] = true;
						}
					});
			this.setState({
				limit: 30,
			}, () => this.refreshDataThrottled());
		}
	}
	
	componentWillUnmount() {
		//console.log(">> EntityList.componentWillUnmount");
		this._isMounted = false;
	}
	
	// Other methods
	
	setQuotes(attribute, value) {
		const hasQuotes = (attribute.type === "TEXT"
			|| attribute.type === "DATE"
			|| attribute.type === "TIMESTAMP"
			|| attribute.type === "VARCHAR"
			|| attribute.type === "CHAR"
			|| attribute.type === "TIME"
			|| attribute.type === "INTERVAL"
			|| attribute.type === "TIMESTAMP_WITH_TIME_ZONE"
			|| attribute.type === "TIME_WITH_TIME_ZONE"
			|| attribute.type === "POINT"
			|| attribute.type === "POLYGON"
		);
		return (hasQuotes ? '"' : '') + value + (hasQuotes ? '"' : '');
	}
	
	getLabelAttributesQueryString(entityModel, entityLocalModel, exceptionAttribute, depth) {
		let str = "";
		
		if (depth == null) {
			depth = 1;
		}
		
		Object.values(entityModel.attributes)
			.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].label)
			.forEach(attribute => {
				if (exceptionAttribute == null || attribute.name != exceptionAttribute.name) {
					str += attribute.name + ", ";
				}
			});
		
		if (depth < 5) {
			Object.values(entityModel.references)
				.filter(reference => reference.referenceAttributeName != null && entityLocalModel.attributes[reference.referenceAttributeName] != null && entityLocalModel.attributes[reference.referenceAttributeName].label)
				.forEach(reference => {
					const model = this.props.context.model;
					
					let subLabel = this.getLabelAttributesQueryString(model.entities[reference.referencedKey.entityName], localModel.entities[reference.referencedKey.entityName], null, depth + 1);
					
					if (subLabel === "") {
						subLabel = Object.values(model.entities[reference.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0].name + ", ";
					}
					
					str += reference.referenceAttributeName + "{ " 
							+ subLabel
							+ "}, ";
				});
		}
		
		return str;
	}
	
	getLabel(item, entityModel, entityLocalModel) {
		if (item == null) {
			return null;
		}
		
		const model = this.props.context.model;
		
		let labels = Object.values(entityModel.attributes)
			.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].label)
			.map(attribute => {
				return {
					order: entityLocalModel.attributes[attribute.name].order,
					label: item[attribute.name],
				}
			});
		
		Object.values(entityModel.references)
			.filter(reference => reference.referenceAttributeName != null && entityLocalModel.attributes[reference.referenceAttributeName] != null && entityLocalModel.attributes[reference.referenceAttributeName].label)
			.forEach(reference => {
				labels.push({
					order: entityLocalModel.attributes[reference.referenceAttributeName].order,
					label: this.getLabel(item[reference.referenceAttributeName], model.entities[reference.referencedKey.entityName], localModel.entities[reference.referencedKey.entityName])
				});
			});
		
		return labels.sort((a, b) => (a.order == null ? Infinity : a.order) - (b.order == null ? Infinity : b.order)).filter(label => label != null && label.label != null).map(label => label.label).join(", ");
	}
	
	getLanguage(lang) {
		let language = "ENGLISH";
		switch (lang) {
			case 'da':
				return 'DANISH';
			case 'nl': 
				return 'DUTCH';
			case 'en_GB': 
				return 'ENGLISH';
			case 'en_US': 
				return 'ENGLISH';
			case 'fi': 
				return 'FINNISH';
			case 'fr_CA': 
				return 'FRENCH';
			case 'fr_FR': 
				return 'FRENCH';
			case 'de': 
				return 'GERMAN';
			case 'it': 
				return 'ITALIAN';
			case 'no': 
				return 'NORWEGIAN';
			case 'pt_BR': 
				return 'PORTUGUESE';
			case 'pt_PT': 
				return 'PORTUGUESE';
			case 'ro': 
				return 'ROMANIAN';
			case 'ru': 
				return 'RUSSIAN';
			case 'es_419': 
				return 'SPANISH';
			case 'es_ES': 
				return 'SPANISH';
			case 'sv': 
				return 'SWEDISH';
			case 'tr': 
				return 'TURKISH';
			default: 
				return 'ENGLISH';
		}
	}
	
	refreshData() {
		try {
			// console.log(">> EntityList.refreshData")
			this.props.context.showActivityIndicator();
			
			const model = this.props.context.model;
			
			const entityModel = model.entities[this.props.entity];
			const entityLocalModel = localModel.entities[this.props.entity];
			
			//console.log("entityModel:");
			//console.log(entityModel);
			//console.log("entityLocalModel:");
			//console.log(entityLocalModel);
			
			let attributes = Object.values(entityModel.attributes)
					.filter(attribute => (attribute.type !== "CUSTOM_TYPE" || entityLocalModel.attributes[attribute.name].type == "DOCUMENT") 
							&& entityLocalModel.attributes 
							&& entityLocalModel.attributes[attribute.name] 
							&& (
									entityLocalModel.attributes[attribute.name].list === undefined 
									|| entityLocalModel.attributes[attribute.name].list
									|| entityLocalModel.attributes[attribute.name].pointInMap
									|| entityLocalModel.attributes[attribute.name].imageInGallery
									|| entityLocalModel.attributes[attribute.name].basicFilter
							)
					);
			
			Object.values(entityModel.references)
					.filter(reference => { 
						//console.log("reference:");
						//console.log(reference);
						return entityModel.attributes[reference.name]
								&& (entityLocalModel.attributes[reference.referenceAttributeName] === undefined
								|| entityLocalModel.attributes[reference.referenceAttributeName].list === undefined
								|| entityLocalModel.attributes[reference.referenceAttributeName].list
								|| entityLocalModel.attributes[reference.referenceAttributeName].basicFilter);
					})
					.forEach(reference => {
						attributes.push({
							name: reference.name,
							entityName: reference.entityName,
							referenceAttributeName: reference.referenceAttributeName,
							attributes: reference.attributes,
							referencedKey: reference.referencedKey,
							type: entityModel.attributes[reference.name].type,
						});
					});
			
			attributes = attributes.filter(attribute => this.props.filterAttribute === undefined || attribute.name !== this.props.filterAttribute);
			
			attributes.sort((a, b) => 
				(	
					entityLocalModel.attributes[a.referenceAttributeName || a.name] === undefined 
						|| entityLocalModel.attributes[a.referenceAttributeName || a.name].order === undefined 
							? Infinity 
							: entityLocalModel.attributes[a.referenceAttributeName || a.name].order
				) - (
					entityLocalModel.attributes[b.referenceAttributeName || b.name] === undefined 
						|| entityLocalModel.attributes[b.referenceAttributeName || b.name].order === undefined 
							? Infinity 
							: entityLocalModel.attributes[b.referenceAttributeName || b.name].order
				)
			);
			
			let keys = Object.values(entityModel.keys);
			const searchEnabled = keys.filter(key => key.textSearch).length > 0;
			let searchCriteria = null;
			let searchAttributeNames = [];
			
			if (searchEnabled) {
				if (this.props.context.state == null) {
					this.props.context.state = {};
				}
				if (this.props.context.state[this.props.entity] == null) {
					this.props.context.state[this.props.entity] = {};
				}
				if (this.searchInput.current != null && this.searchInput.current.value != null) {
					this.props.context.state[this.props.entity].searchCriteria = this.searchInput.current.value;
				}
				if (this.props.context.state[this.props.entity].searchCriteria == null) {
					this.props.context.state[this.props.entity].searchCriteria = "";
				}
				searchCriteria = (this.props.context.state[this.props.entity].searchCriteria != null ? this.props.context.state[this.props.entity].searchCriteria : "");
				searchCriteria = (searchCriteria === null || searchCriteria.trim() === "" ? null : searchCriteria.replace(/"/gi, "'").replace(/\\/gi, "").trim());
				keys.filter(key => key.textSearch).map(key => Object.values(key.attributes).map(attribute => attribute.name in searchAttributeNames ? null : searchAttributeNames.push(attribute.name)));
			}
			
			//console.log("Attributes: ");
			//console.log(attributes);
			
			if (this.basicFilterRefs == null) {
				this.basicFilterRefs = {};
			}
			
			//console.log(entityLocalModel);
			//console.log(attributes);
			
			attributes
					.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].basicFilter
							|| entityLocalModel.attributes[attribute.referenceAttributeName] != null && entityLocalModel.attributes[attribute.referenceAttributeName].basicFilter)
					.forEach(attribute => {
				if (attribute.incomingReferenceAttributeName === undefined)	{
					if (!attribute.array
							&& attribute.referenceAttributeName !== undefined) {
						if (this.basicFilterRefs[attribute.name] == null) {
							this.basicFilterRefs[attribute.name] = React.createRef();
						}
					}
					else if (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") {
						if (this.basicFilterRefs[attribute.name] == null) {
							this.basicFilterRefs[attribute.name] = React.createRef();
						}
					}
					else if (!attribute.array
							&& (attribute.type === "INTEGER"
								|| attribute.type === "SMALLINT"
								|| attribute.type === "BIGINT"
								|| attribute.type === "SERIAL"
								|| attribute.type === "DECIMAL"
								|| attribute.type === "DOUBLE_PRECISION"
								|| attribute.type === "REAL"
								|| attribute.type === "MONEY"
								|| attribute.type === "SMALLSERIAL"
								|| attribute.type === "BIGSERIAL"
								
								|| attribute.type === "DATE")
								|| attribute.type === "TIMESTAMP"
								|| attribute.type === "TIME") {
						if (this.basicFilterRefs[attribute.name + "From"] == null) {
							this.basicFilterRefs[attribute.name + "From"] = React.createRef();
							this.basicFilterRefs[attribute.name + "To"] = React.createRef();
						}
					}
				}	
			});
			
			let where = [];
			attributes
					.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].basicFilter
							|| entityLocalModel.attributes[attribute.referenceAttributeName] != null && entityLocalModel.attributes[attribute.referenceAttributeName].basicFilter)
					.forEach(attribute => {
				
				if (!attribute.array
						&& attribute.referenceAttributeName !== undefined) {
					if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
						let value = (this.basicFilterRefs[attribute.name].current.select.state.value == null ? null : this.basicFilterRefs[attribute.name].current.select.state.value);
						this.props.context.state[this.props.entity].basicFilters[attribute.name] = value;
					} 
					let value = this.props.context.state[this.props.entity].basicFilters[attribute.name];
					if (value != null && value != "") {
						where.push('{' + attribute.name + ': {EQ: ' + value.value + '}}');
					}
				}
				else if (attribute.enumType === undefined && (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")) {
					if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
						let value = this.basicFilterRefs[attribute.name].current.value;
						this.props.context.state[this.props.entity].basicFilters[attribute.name] = value;
					}
					let value = this.props.context.state[this.props.entity].basicFilters[attribute.name];
					if (value != null && value != "") {
						value = (value.replace(/[^Á-ÚA-Z0-9ñÑ\s"']/gi, '').trim() === "" ? null : value.replace(/[^Á-ÚA-Z0-9ñÑ\s"']/gi, '').replace(/"/gi, "'").trim().split(/\s+/).join(":* &") + ":*");
						where.push('{' + attribute.name + ': {SEARCH: {query: "' + value + '" config: ' + this.getLanguage(entityLocalModel.language) + '}}}');
					}
				}
				else if (attribute.enumType !== undefined && (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")) {
					if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
						let value = this.basicFilterRefs[attribute.name].current.value;
						if (value == "") {
							value = null;
						}
						this.props.context.state[this.props.entity].basicFilters[attribute.name] = value;
					}
					let value = this.props.context.state[this.props.entity].basicFilters[attribute.name];
					if (value != null) {
						where.push('{' + attribute.name + ': {EQ: ' + value + '}}');
					}
				}
				else if (!attribute.array
						&& attribute.type === "BOOLEAN") {
					if (this.props.context.state[this.props.entity].basicFilters[attribute.name] != null) {
						if (this.props.context.state[this.props.entity].basicFilters[attribute.name] == false) {
							where.push('{OR: [{' + attribute.name + ': {EQ: false}} {' + attribute.name + ': {IS_NULL: true}}]}');
						}
						else {
							where.push('{' + attribute.name + ': {EQ: ' + this.props.context.state[this.props.entity].basicFilters[attribute.name] + '}}');
						}
					}
				}
				else if (!attribute.array
						&& (attribute.type === "INTEGER"
							|| attribute.type === "SMALLINT"
							|| attribute.type === "BIGINT"
							|| attribute.type === "SERIAL"
							|| attribute.type === "DECIMAL"
							|| attribute.type === "DOUBLE_PRECISION"
							|| attribute.type === "REAL"
							|| attribute.type === "MONEY"
							|| attribute.type === "SMALLSERIAL"
							|| attribute.type === "BIGSERIAL")) {
					if (this.basicFilterRefs[attribute.name + "From"] != null && this.basicFilterRefs[attribute.name + "From"].current != null) {
						let value = this.basicFilterRefs[attribute.name + "From"].current.value;
						this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"] = value;
					}
					let value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"];
					if (value != null && value != "") {
						where.push('{' + attribute.name + ': {GE: ' + Number(value) + '}}');
					}
					if (this.basicFilterRefs[attribute.name + "To"] != null && this.basicFilterRefs[attribute.name + "To"].current != null) {
						let value = this.basicFilterRefs[attribute.name + "To"].current.value;
						this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"] = value;
					}
					value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"];
					if (value != null && value != "") {
						where.push('{' + attribute.name + ': {LE: ' + Number(value) + '}}');
					}
				}
				else if (!attribute.array
						&& (attribute.type === "DATE" || attribute.type === "TIMESTAMP" || attribute.type === "TIME")) {
					if (this.basicFilterRefs[attribute.name + "From"] != null && this.basicFilterRefs[attribute.name + "From"].current != null) {
						let value = this.basicFilterRefs[attribute.name + "From"].current.value;
						this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"] = value;
					}
					let value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"];
					if (value != null && value != "") {
						where.push('{' + attribute.name + ': {GE: "' + value.replace("T", " ") + '"}}');
					}
					if (this.basicFilterRefs[attribute.name + "To"] != null && this.basicFilterRefs[attribute.name + "To"].current != null) {
						let value = this.basicFilterRefs[attribute.name + "To"].current.value;
						this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"] = value;
					}
					value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"];
					if (value != null && value != "") {
						where.push('{' + attribute.name + ': {LE: "' + value.replace("T", " ") + '"}}');
					}
				}
			});
			
			let orderBy = null;
			if (this.props.context.state[this.props.entity].orderBy !== null) {
				orderBy = " orderBy: { attribute: " + this.props.context.state[this.props.entity].orderBy + " direction: " + (this.props.context.state[this.props.entity].orderDirection === 'asc' ? 'ASC' : 'DESC') + " nullsGo: " + (this.props.context.state[this.props.entity].orderDirection === 'asc' ? 'LAST' : 'FIRST') + "} ";
			}
			else {
				let labelAttributes = Object.values(entityModel.attributes)
						.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].label && !attribute.array);
				if (labelAttributes != null && labelAttributes.length > 0) {
					orderBy = " orderBy: [";
					labelAttributes.slice(0, 1).forEach(attribute => { orderBy += "{ attribute: " + attribute.name + " nullsGo: LAST }, "});
					orderBy += "] ";
				}
			}
			
			if (searchEnabled && searchCriteria != null) {
				where.push('{' + searchAttributeNames[0] + ': {SEARCH: {query: "' + searchCriteria + '" config: ' + this.getLanguage(entityLocalModel.language) + '}}}');
			}
			if (this.props.filterAttribute !== undefined && this.props.filterValue !== undefined) {
				where.push('{' + this.props.filterAttribute + ': {EQ: ' + this.setQuotes(entityModel.attributes[this.props.filterAttribute], this.props.filterValue) + '}}');
			}
			
			// Although several attributes can be added to the primary key in the API, 
			// we have simplified and assume only one in the administrative tool, because of the router, surrogate key, ...
			let primaryKeys = keys.filter(key => key.primaryKey);
			if (primaryKeys == null 
					|| primaryKeys.length == 0 
					|| primaryKeys.length > 1 
					|| primaryKeys[0].attributes == null
					|| primaryKeys[0].attributes.length == 0
					|| primaryKeys[0].attributes.length > 1) {
				
				this.setState({
					message: this.props.t('primaryKeyWithOnlyOneAttributeNeeded'),
					messageError: true,
					messageOpened: true,
					deleteConfirmationDialogOpened: false,
				}, () => this.props.context.hideActivityIndicator());
			}
			else {
				let keyAttribute = primaryKeys[0].attributes[0];
				
				//console.log(entityLocalModel);
				//console.log(entityModel);
				
				if (this.props.disabled) {
					let state = {
							keyAttribute: keyAttribute,
							currentEntity: this.props.entity,
							data: [],
							attributes: attributes,
							searchEnabled: searchEnabled,
							scrollEnabled: false,
					};
					this.setState(state, () => this.props.context.hideActivityIndicator());
				}
				else {
					const query = 
							'{ ' +
							'   token: refreshToken(token: "' + this.props.context.accessToken + '")' +
							'	result:' + this.props.entity.replace(".", "_") + 'List(' +
							'		limit: ' + this.state.limit +
							(orderBy != null ? orderBy : "") + 
							(where.length > 0 ? ' where: {AND: [' + where.join(" ") + ']}' : '') +
							'	) { ' + keyAttribute.name + ', ' +
							'   	' + attributes.map(attribute => {
									if (attribute.referenceAttributeName !== undefined) {
										let subPrimaryKeys = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey);
										if (subPrimaryKeys == null 
												|| subPrimaryKeys.length == 0 
												|| subPrimaryKeys.length > 1 
												|| subPrimaryKeys[0].attributes == null
												|| subPrimaryKeys[0].attributes.length == 0
												|| subPrimaryKeys[0].attributes.length > 1) {
											
											this.setState({
												message: attribute.referencedKey.entityName + ": " + this.props.t('primaryKeyWithOnlyOneAttributeNeeded'),
												messageError: true,
												messageOpened: true,
												deleteConfirmationDialogOpened: false,
											}, () => this.props.context.hideActivityIndicator());
											return "";
										}
										else {
											let subKeyAttribute = subPrimaryKeys[0].attributes[0];
											return attribute.referenceAttributeName + "{ " + subKeyAttribute.name + ", " + this.getLabelAttributesQueryString(model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName], subKeyAttribute) + "}";
										}
									}
									else {
										if (entityLocalModel.attributes[attribute.name].type == "DOCUMENT") {
											return attribute.name + " { name thumbnail type }";
										}
										else {
											return attribute.name;
										}
									}
								}) + " " +
								
								Object.keys(entityLocalModel.attributes)
										.filter(attributeName => entityLocalModel.attributes[attributeName].filteredWhenEmpty
												&& this.props.context.state[this.props.entity].basicFilters[attributeName])
										.map(attributeName => attributeName + "(joinType: INNER limit: 1) { " + Object.values(Object.values(model.entities).filter(entity => Object.values(entity.references).filter(reference => reference.referencedKey.entityName == entityModel.schema + "." + entityModel.name).length > 0).filter(entity => attributeName.startsWith(entity.name))[0].keys).filter(key => key.primaryKey)[0].attributes[0].name + " }")
										.join(" ") +
								
							'	} ' +
							'}';
					
					const variables = {
			    		authorization: this.props.context.accessToken
			    	};
					let request = JSON.stringify({query: query, variables: variables});
					
					//console.log("Query: " + query);
					//console.log(variables);
					
					fetch(this.props.context.baseUrl + "/graphql", {
						method: "POST",
						body: request
					})
					.then(response => response.json())
					.then(json => {
						if (json != null
								&& json.errors != null
								&& json.errors[0] != null
								&& json.errors[0].message == "SessionTimeout") {
							document.location = '/login';
						}
						
						// This is considered an anti-pattern
						if (this._isMounted) {
							let diagramModel = null;
							
							if (this.state.data != null) {
								this.state.data.filter(item => item._selected)
										.forEach(item => {
											json.data["result"].filter(item2 => item2[keyAttribute.name] == item[keyAttribute.name])
													.forEach(item2 => {
														item2._selected = (this.state.limit == 30 ? false : item._selected);
													});
										});
							}
							
							// Load diagram
							if (this.props.entity == "Models.Entity") {
								
								diagramModel = new DiagramModel();
								
								json.data["result"].forEach(entity => {
									
									idByName[entity.schema + "." + entity.name] = entity.id;
									
									const node = new DefaultNodeModel({
										name: entity.schema + "." + entity.name,
										color: this.props.theme.palette.secondary.main,
									});
									node.setPosition(100, 100);
									
									let inPort = node.addPort(new AdvancedPortModel(true, "SERIAL id", "SERIAL id"));
									inPort.setLocked(true);
									
									Object.values(model.entities[entity.schema + "." + entity.name].attributes)
											.filter(attribute => attribute.name != "id")
											.forEach(attribute => {
										let outPort = node.addPort(new AdvancedPortModel(false, attribute.type + " " + attribute.name, attribute.type + " " + attribute.name));
										if (attribute.type != "INTEGER") {
											outPort.setLocked(true);
										}
									});
									
									node.updateDimensions({height: 26 + node.portsOut.length * 16, width: 400});
									
									node.registerListener({
										entityRemoved: event => {
											event.stopPropagation();
											//console.log("Delete not implemented");
										},
										eventDidFire: this.handleDiagramEvent,
									});
									
									diagramModel.addAll(node);
								});
								
								json.data["result"].forEach(entity => {
									Object.values(model.entities[entity.schema + "." + entity.name].references).forEach(reference => {
										let sourceNode = Object.values(diagramModel.getActiveNodeLayer().getNodes()).filter(node => node.options.name == entity.schema + "." + entity.name)[0];
										let targetNode = Object.values(diagramModel.getActiveNodeLayer().getNodes()).filter(node => node.options.name == reference.referencedKey.entityName)[0];
										
										if (sourceNode != null && targetNode != null) {
											let outPort = sourceNode.portsOut.filter(port => port.options.label.split(" ")[1] == reference.name)[0];
											let inPort = targetNode.portsIn[0];
											//console.log(sourceNode);
											//console.log(targetNode);
											//console.log(outPort);
											//console.log(inPort);
											
											if (outPort != null) {
												let link = outPort.createLinkModel();
												link.setSourcePort(outPort);
												link.setTargetPort(inPort);
												link.setColor(this.props.theme.palette.secondary.main);
												
												link.registerListener({
													eventDidFire: this.handleDiagramEvent
												});
												
												outPort.setLocked(true);
												diagramModel.addAll(link);
											}
										}
									});
								});
								
								this.dagreEngine.redistribute(diagramModel);
								
								diagramModel.registerListener({
									eventDidFire: this.handleDiagramEvent
								});
							}
							
							let scrollEnabled = true;
							if (json.data["result"] != null && json.data["result"].length < this.state.limit) {
								scrollEnabled = false;
							}
							
							let state = {
								keyAttribute: keyAttribute,
								currentEntity: this.props.entity,
								data: json.data["result"],
								attributes: attributes,
								searchEnabled: searchEnabled,
								scrollEnabled: scrollEnabled,
								diagramModel: diagramModel,
							};
							
							Object.values(entityLocalModel.attributes)
									.filter(attribute => attribute.pointInMap)
									.forEach(attribute => {
										if (json.data["result"] != null) {
											let result = json.data["result"].filter(item => item[attribute.name] != null);
											if (result.length > 0) {
												let location = result[0][attribute.name];
												if (location != null && location != "") {
													location = location.substr(1, location.length - 2);
													state.latitude = Number(location.split(",")[0]);
													state.longitude = Number(location.split(",")[1]);
												}
											}
											else {
												state.latitude = 40.529179;
												state.longitude = -3.651276;
											}
										}
										else {
											state.latitude = 40.529179;
											state.longitude = -3.651276;
										}
									});
							
							state.questions = undefined;
							this.setState(state, () => this.props.context.hideActivityIndicator());
							
							this.props.context.setState({
								accessToken: json.data.token,
							});

							if (entityLocalModel.questions != null) {
								Object.values(entityLocalModel.questions).filter(question => question.list).forEach(question => {
									
									const questionQuery = '{QuestionURL(id: ' + question.id + (question.useIds ? ' idsParameter: [' + json.data["result"].map(item => item.id).join(",") + ']': '') + ')}';
									
									const questionQueryVariables = {
							    		authorization: this.props.context.accessToken
							    	};
									let request = JSON.stringify({query: questionQuery, variables: questionQueryVariables});
									
									fetch(this.props.context.baseUrl + "/graphql", {
										method: "POST",
										body: request
									})
									.then(response => response.json())
									.then(json => {
										if (json.errors != null && json.errors.length > 0) {
											this.setState({
												message: json.errors[0].message,
												messageError: true,
												messageOpened: true,
											});
										}
										else {
											this.setState((state, props) => {
												let questions = state.questions;
												if (questions == null) {
													questions = {};
												}
												questions[question.name] = {
													url: json.data.QuestionURL
												};
												return {
													questions: questions,
												};
											});
										}
									});
								});
							}
						}
						else {
							this.props.context.hideActivityIndicator();
						}
					})
					.catch(error => {
						console.log("!!!!! Retrying...");
						console.log(error);
						console.log(request);
						setTimeout(this.refreshDataThrottled(), 500);
					});
				}
			}
		}
		catch (error) {
			console.log(error);
			this.go("/login");
		}
	}
	
	// Render
	render() {
		// console.log(">> EntityList.render");
		// const { orderBy, orderDirection } = this.state;
		const { classes, t, theme } = this.props;
		
		if (this.state != null 
				&& this.props.context.model !== null
				&& this.state.currentEntity === this.props.entity
				&& this.state.data != null) {
			
			const selectedItems = this.state.data.filter(item => item._selected);
			const { orderBy, orderDirection } = this.props.context.state[this.props.entity];
			
			const model = this.props.context.model;
			const entityLocalModel = localModel.entities[this.props.entity];
			const entityModel = model.entities[this.props.entity];
			
			let attributes = this.state.attributes;
			
			let localAttributes = Object.values(entityLocalModel.attributes);
			
			let calendarEnabled = localAttributes.filter(attribute => attribute.eventStart).length > 0;
			let mapEnabled = localAttributes.filter(attribute => attribute.pointInMap).length > 0;
			let galleryEnabled = localAttributes.filter(attribute => attribute.imageInGallery).length > 0;
			let diagramEnabled = this.props.entity == 'Models.Entity';
			
			let mapAttribute = null;
			if (mapEnabled) {
				mapAttribute = localAttributes.filter(attribute => attribute.pointInMap)[0];
			}
			
			let galleryAttribute = null;
			if (galleryEnabled) {
				galleryAttribute = localAttributes.filter(attribute => attribute.imageInGallery)[0];
			}
			
			//console.log("Attributes: ");
			//console.log(attributes);
			
			if (this.searchInput.current != null) {
				if ((this.searchInput.current.value == null || this.searchInput.current.value == "") && this.props.context.state[this.props.entity] != null && this.props.context.state[this.props.entity].searchCriteria != null) {
					this.searchInput.current.value = this.props.context.state[this.props.entity].searchCriteria;
				}
			}
			
			attributes
					.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].basicFilter
							|| entityLocalModel.attributes[attribute.referenceAttributeName] != null && entityLocalModel.attributes[attribute.referenceAttributeName].basicFilter)
					.forEach(attribute => {
				
				if (!attribute.array
						&& attribute.referenceAttributeName !== undefined) {
					if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
						if (this.basicFilterRefs[attribute.name].current.value == null && this.props.context.state[this.props.entity] != null && this.props.context.state[this.props.entity].basicFilters[attribute.name] != null) {
							this.basicFilterRefs[attribute.name].current.select.select.setValue(this.props.context.state[this.props.entity].basicFilters[attribute.name], 'select-option');
						}
					}
				}
				else if (attribute.enumType === undefined && (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")) {
					if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
						if ((this.basicFilterRefs[attribute.name].current.value == null || this.basicFilterRefs[attribute.name].current.value == "") && this.props.context.state[this.props.entity] != null && this.props.context.state[this.props.entity].basicFilters[attribute.name] != null) {
							this.basicFilterRefs[attribute.name].current.value = this.props.context.state[this.props.entity].basicFilters[attribute.name];
						}
					}
				}
				else if (attribute.enumType !== undefined && (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")) {
					if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
						if ((this.basicFilterRefs[attribute.name].current.value == null || this.basicFilterRefs[attribute.name].current.value == "") && this.props.context.state[this.props.entity].basicFilters[attribute.name] != null) {
							Object.values(this.basicFilterRefs[attribute.name].current.options).forEach(option => option.selected = this.props.context.state[this.props.entity].basicFilters[attribute.name] == option.value);
						}
					}
				}
				else if (!attribute.array
						&& (attribute.type === "INTEGER"
							|| attribute.type === "SMALLINT"
							|| attribute.type === "BIGINT"
							|| attribute.type === "SERIAL"
							|| attribute.type === "DECIMAL"
							|| attribute.type === "DOUBLE_PRECISION"
							|| attribute.type === "REAL"
							|| attribute.type === "MONEY"
							|| attribute.type === "SMALLSERIAL"
							|| attribute.type === "BIGSERIAL")) {
					if (this.basicFilterRefs[attribute.name + "From"] != null && this.basicFilterRefs[attribute.name + "From"].current != null) {
						if ((this.basicFilterRefs[attribute.name + "From"].current.value == null || this.basicFilterRefs[attribute.name + "From"].current.value == "") && this.props.context.state[this.props.entity] != null && this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"] != null) {
							this.basicFilterRefs[attribute.name + "From"].current.value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"];
						}
					}
					if (this.basicFilterRefs[attribute.name + "To"] != null && this.basicFilterRefs[attribute.name + "To"].current != null) {
						if ((this.basicFilterRefs[attribute.name + "To"].current.value == null || this.basicFilterRefs[attribute.name + "To"].current.value == "") && this.props.context.state[this.props.entity] != null && this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"] != null) {
							this.basicFilterRefs[attribute.name + "To"].current.value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"];
						}
					}
				}
				else if (!attribute.array
						&& (attribute.type === "DATE" || attribute.type === "TIMESTAMP" || attribute.type === "TIME")) {
					if (this.basicFilterRefs[attribute.name + "From"] != null && this.basicFilterRefs[attribute.name + "From"].current != null) {
						if ((this.basicFilterRefs[attribute.name + "From"].current.value == null || this.basicFilterRefs[attribute.name + "From"].current.value == "") && this.props.context.state[this.props.entity] != null && this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"] != null) {
							this.basicFilterRefs[attribute.name + "From"].current.value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"];
						}
					}
					if (this.basicFilterRefs[attribute.name + "To"] != null && this.basicFilterRefs[attribute.name + "To"].current != null) {
						if ((this.basicFilterRefs[attribute.name + "To"].current.value == null || this.basicFilterRefs[attribute.name + "To"].current.value == "") && this.props.context.state[this.props.entity] != null && this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"] != null) {
							this.basicFilterRefs[attribute.name + "To"].current.value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"];
						}
					}
				}
			});
			
			if (this.props.context.state[this.props.entity].mode == "diagram") {
				//this.state.diagramModel.layers = this.state.diagramModel.getLayers().reverse();
				engine.setModel(this.state.diagramModel);
			}

			let questions = entityLocalModel.questions != null && Object.values(entityLocalModel.questions).filter(question => question.list);
			if (questions == null) {
				questions = [];
			}
			
			return this.props.context.model !== null && (
				<form onSubmit={
						(event) => {
							event.preventDefault(); 
							this.refreshData();
						}
				}>
					<Grid container spacing={24}>
						<Grid item xs={12} sm={questions.length > 0 ? 8 : 12} lg={questions.length > 0 ? 9 : 12} xl={questions.length > 0 ? 10 : 12}>
							<Paper square>
								<Toolbar className={selectedItems.length === 0 ? classes.toolbar : classes.toolbar2}>
									<Grid container spacing={24} justify="flex-start" alignItems="center">
										<Grid item xs sm>
											{ selectedItems.length > 0 ? (
												<Typography variant="h6" color="inherit" noWrap>{selectedItems.length} {t('selected')}</Typography>
											) : (
												<Typography variant="h6" color="inherit" noWrap>{t('e.' + this.props.entity + '.pluralName')}</Typography>
											)}
										</Grid>
										
										{ selectedItems.length > 0 ? (
										<Grid item xs sm style={{textAlign: "end", whiteSpace: "nowrap"}}>
											<Tooltip title={t('view')} disableFocusListener>
												<span>
													<IconButton 
															aria-label={t('view')} 
															classes={{
																root: classes.action,
																disabled: classes.disabledAction,
															}}
															disabled={selectedItems.length !== 1}
															onClick={this.handleViewClick}>
														<DescriptionIcon/>
													</IconButton>
												</span>
											</Tooltip>
											{ (model.super || model.entities[this.props.entity].privileges["UPDATE"] !== undefined) && (
												<Tooltip title={t('edit')} disableFocusListener>
													<span>
														<IconButton 
																aria-label={t('edit')}
																classes={{
																	root: classes.action,
																	disabled: classes.disabledAction,
																}}
																disabled={selectedItems.length !== 1}
																onClick={this.handleEditClick}>
															<EditIcon/>
														</IconButton>
													</span>
												</Tooltip>
											)}
											{ (model.super || model.entities[this.props.entity].privileges["DELETE"] !== undefined) && (
												<Tooltip title={t('delete')} disableFocusListener>
													<span>
														<IconButton 
																aria-label={t('delete')} 
																classes={{
																	root: classes.action,
																	disabled: classes.disabledAction,
																}}
																onClick={this.handleDeleteClick}>
															<DeleteIcon/>
														</IconButton>
													</span>
												</Tooltip>
											)}
											{(model.super && !this.props.entity.startsWith("Models.") &&
												<Tooltip title={t('download')} disableFocusListener>
													<span>
														<IconButton 
																aria-label={t('download')} 
																classes={{
																	root: classes.action,
																	disabled: classes.disabledAction,
																}}
																onClick={this.handleDownloadClick}>
															<DownloadIcon/>
														</IconButton>
													</span>
												</Tooltip>
											)}
										</Grid>
									) : (
										<>
											<Grid item xs sm style={{textAlign: "end", whiteSpace: "nowrap"}}>
												{
													Object.keys(entityLocalModel.attributes)
															.filter(attributeName => entityLocalModel.attributes[attributeName].filteredWhenEmpty)
															.map(attributeName => (
																	<FormControlLabel key={attributeName + "Filter"}
																		control={
																				<Checkbox color="secondary" 
																					onChange={(event, checked) => this.handleCheckboxChange(event, checked, attributeName)} 
																					checked={this.props.context.state[this.props.entity].basicFilters[attributeName]}
																				/>
																		}
																		label={t('e.' + this.props.entity + '.pluralName') + " " + t('with') + " " + t('e.' + this.props.entity.split(".")[0] + "." + attributeName.split("ListVia")[0] + '.pluralName').toLowerCase()}/>
															))
												}
												{
													(calendarEnabled
														|| mapEnabled
														|| galleryEnabled
														|| diagramEnabled)
														&&
													<div className={classes.toggleContainer}>
														<ToggleButtonGroup selected value={this.props.context.state[this.props.entity].mode} exclusive onChange={this.handleChangeMode}>
															<ToggleButton value="table">
																<Tooltip title={t('tableView')} disableFocusListener>
																	<ListIcon/>
																</Tooltip>
															</ToggleButton>
															{
																calendarEnabled
																		&&
																<ToggleButton value="calendar">
																	<Tooltip title={t('calendarView')} disableFocusListener>
																		<EventIcon/>
																	</Tooltip>
																</ToggleButton>
															}
															{
																mapEnabled
																		&&
																<ToggleButton value="map">
																	<Tooltip title={t('mapView')} disableFocusListener>
																		<MapIcon/>
																	</Tooltip>
																</ToggleButton>
															}
															{
																galleryEnabled
																		&&
																<ToggleButton value="gallery">
																	<Tooltip title={t('galleryView')} disableFocusListener>
																		<GalleryIcon/>
																	</Tooltip>
																</ToggleButton>
															}
															{
																diagramEnabled
																		&&
																<ToggleButton value="diagram">
																	<Tooltip title={t('diagramView')} disableFocusListener>
																		<DiagramIcon/>
																	</Tooltip>
																</ToggleButton>
															}
														</ToggleButtonGroup>
													</div>
												}
												{
													localAttributes.filter(attribute => attribute.basicFilter).length > 0
														&&
													<div className={classes.toggleContainer}>
														<Tooltip title={this.props.context.state[this.props.entity].basicFiltersEnabled ? t('disableBasicFilters') : t('enableBasicFilters')} disableFocusListener>
															<ToggleButtonGroup selected value={this.props.context.state[this.props.entity].basicFiltersEnabled} exclusive onChange={this.handleToggleBasicFilters}>
																<ToggleButton value={true} disabled={this.props.disabled}>
																	<FilterListIcon/>
																</ToggleButton>
															</ToggleButtonGroup>
														</Tooltip>
													</div>
												}
												{
													model.edition == 'Enterprise'
															&& false // Disable intelligence
															&&
													<div className={classes.toggleContainer}>
														<Tooltip title={this.state.intelligenceEnabled ? t('disableIntelligence') : t('enableIntelligence')} disableFocusListener>
															<ToggleButtonGroup selected value={this.state.intelligenceEnabled} exclusive onChange={this.handleToggleIntelligence}>
																<ToggleButton value={true} disabled={this.props.disabled}>
																	<MemoryIcon/>
																</ToggleButton>
															</ToggleButtonGroup>
														</Tooltip>
													</div>
												}
												{(model.super && !this.props.entity.startsWith("Models.") &&
													<Tooltip title={t('download')} disableFocusListener>
														<span>
															<IconButton 
																	disabled={this.props.disabled}
																	aria-label={t('download')} 
																	onClick={this.handleDownloadClick}>
																<DownloadIcon/>
															</IconButton>
														</span>
													</Tooltip>
												)}
											</Grid>
											{ this.state.searchEnabled && (
												<Grid item xs sm style={{textAlign: "end", whiteSpace: "nowrap"}}>
													<Input onKeyDown={this.handleSearchKeyDown} style={{width: "200px"}} disabled={this.props.disabled} inputRef={this.searchInput} placeholder={t('search') + "..."} autoFocus={this.props.main}/>
													<Tooltip title={t('search')} disableFocusListener>
														<span>
															<IconButton 
																	disabled={this.props.disabled}
																	aria-label={t('search')} 
																	type="submit"
															>
																<SearchIcon/>
															</IconButton>
														</span>
													</Tooltip>
													<Tooltip title={t('clearCriteria')} disableFocusListener>
														<span>
															<IconButton 
																	disabled={this.props.disabled}
																	aria-label={t('clearCriteria')} 
																	onClick={this.handleClearCriteriaClick}>
																<ClearIcon/>
															</IconButton>
														</span>
													</Tooltip>
												</Grid>
											)}
										</>
									)}
									
									{
										this.props.context.state[this.props.entity].mode == "diagram"
												&&
											<Tooltip title={t('zoomToFit')} disableFocusListener>
												<Fab data-qa={this.props.entity + "-zoom-to-fit"}
														onClick={event => engine.zoomToFit()}
														color="primary"
														style={{backgroundColor: theme.palette.primary.main}}
														className={classes.fab2}
												>
													<ZoomToFitIcon/>
												</Fab>
											</Tooltip>
									}
									{
										(
											model.super 
													&& this.props.entity != "Models.EntityReference"
													&& this.props.entity != "Models.EntityReferenceAttribute"
													|| model.entities[this.props.entity].privileges != null && model.entities[this.props.entity].privileges["INSERT"] !== undefined) 
													&& ((this.props.main && 
												<Tooltip title={t('new')} disableFocusListener>
													<Fab data-qa={this.props.entity + "-create-button"}
															onClick={this.handleNewClick}
															color="primary"
															style={{backgroundColor: theme.palette.primary.main}}
															className={classes.fab}
													>
														<AddIcon/>
													</Fab>
												</Tooltip>
											) || (
												<Tooltip title={t('new')} disableFocusListener>
													<div>
														<IconButton data-qa={this.props.entity + "-" + this.props.filterAttribute + "-create-button"}
																classes={{
																	root: (selectedItems.length > 0 ? classes.action : ''),
																}}
																disabled={this.props.disabled}
																aria-label={t('new')} 
																onClick={this.handleNewClick}>
															<AddIcon/>
														</IconButton>
													</div>
												</Tooltip>
											)
										)
									}
									</Grid>
								</Toolbar>
							</Paper>
							{
								this.props.context.state[this.props.entity].basicFiltersEnabled && (
									<Paper square className={classes.paper}>
										<Grid container spacing={24}>
											<Grid item xs={12} sm={(this.state.searchEnabled ? 12 : 6)}>
												<Typography variant="subtitle2" 
														className={classes.formGroup} 
														color="inherit" 
														noWrap>
													{t('searchCriteria')}
												</Typography>
											</Grid>
											{ !this.state.searchEnabled && (
												<Grid item xs={12} sm={6} style={{textAlign: "end", whiteSpace: "nowrap"}}>
													<Tooltip title={t('search')} disableFocusListener>
														<span>
															<IconButton 
																	disabled={this.props.disabled}
																	aria-label={t('search')}
																	type="submit"
															>
																<SearchIcon/>
															</IconButton>
														</span>
													</Tooltip>
													<Tooltip title={t('clearCriteria')} disableFocusListener>
														<span>
															<IconButton 
																	disabled={this.props.disabled}
																	aria-label={t('clearCriteria')} 
																	onClick={this.handleClearCriteriaClick}>
																<ClearIcon/>
															</IconButton>
														</span>
													</Tooltip>
												</Grid>
											)}
										{
											attributes
													.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].basicFilter
															|| entityLocalModel.attributes[attribute.referenceAttributeName] != null && entityLocalModel.attributes[attribute.referenceAttributeName].basicFilter)
													.map(attribute => 
													
												// Todos los campos que no son referencias inversas
												(
													attribute.incomingReferenceAttributeName === undefined 
															&& 
													(
														// Campos de texto enumerado con un único valor o con múltiples valores
														(
															(attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
																	&& attribute.enumType !== undefined 
																	&& 
																<Grid key={attribute.name} item xs={12} sm={6}
																	style={{paddingTop: "10px"}}
																>
																	<FormControl fullWidth>
																		<InputLabel	shrink>{t('e.' + this.props.entity + '.a.' + attribute.name)}</InputLabel>
																		<br/>
																		<Select 
																				inputRef={this.basicFilterRefs[attribute.name]}
																				native
																		>
																			<option value=""></option>
																			{ 
																				Object.values(attribute.enumType.values).map(value => (
																					<option key={value} value={value}>{t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.v.' + value)}</option>
																				))
																			}
																		</Select>
																	</FormControl>
																</Grid>
														)
														||
														
														// Campos de códigos de barras
														(
															entityLocalModel.attributes[attribute.name].type === 'BARCODE'
																	&& 
																<Grid key={attribute.name} item xs={12} sm={6}
																	style={{paddingTop: "10px"}}
																>
																	<Barcode
																			inputRef={this.basicFilterRefs[attribute.name]}
																			label={t('e.' + this.props.entity + '.a.' + attribute.name)} 
																			barcodeType={entityLocalModel.attributes[attribute.name].barcodeType}
																	/>
																</Grid>
														)
														||
														
														// Campos de texto sencillos con un único valor o con múltiples valores
														(
															!attribute.rich
																	&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
																	&& attribute.enumType === undefined 
																	&& entityLocalModel.attributes[attribute.name].type != 'BARCODE'
																	&& 
																<Grid key={attribute.name} item xs={12} sm={6}>
																	<TextField
																			inputRef={this.basicFilterRefs[attribute.name]}
																			label={t('e.' + this.props.entity + '.a.' + attribute.name)}
																			fullWidth
																			InputLabelProps={{shrink: true}}
																	/>
																</Grid>
														)
														||
														
														// Campos booleanos
														(
															!attribute.array
																	&& attribute.type === "BOOLEAN" 
																	&& 
																<Grid key={attribute.name} item xs={12} sm={6}>
																	<FormControlLabel
																			control={
																					<Checkbox color="secondary" 
																							indeterminate={this.props.context.state[this.props.entity].basicFilters[attribute.name] == null}
																							onChange={() => this.handleBasicFilterCheckboxChange(attribute.name)} 
																							checked={this.props.context.state[this.props.entity].basicFilters[attribute.name] === true}
																					/>
																			}
																			label={t('e.' + this.props.entity + '.a.' + attribute.name)}/>
																</Grid>
														)
														||
														
														// Referencias a otras entidades, cardinalidad uno
														(
															!attribute.array
																	&& attribute.referenceAttributeName !== undefined
																	&&
																<Grid key={attribute.name} item xs={12} sm={6}>
																	<AutoComplete
																			entityName={Object.values(entityModel.references).filter(reference => reference.name === attribute.name)[0].referencedKey.entityName}
																			asyncRef={this.basicFilterRefs[attribute.name]}
																			label={t('e.' + this.props.entity + '.a.' + attribute.name)}
																	/>
																</Grid>
														)			
														||
														
														// Campos de tipo entero con un único valor
														// Campos de tipo numérico con decimales con un único valor
														(
															attribute.referenceAttributeName === undefined
																	&& !attribute.array 
																	&& (attribute.type === "INTEGER"
																			|| attribute.type === "SMALLINT"
																			|| attribute.type === "BIGINT"
																			|| attribute.type === "SERIAL"
																			|| attribute.type === "DECIMAL"
																			|| attribute.type === "DOUBLE_PRECISION"
																			|| attribute.type === "REAL"
																			|| attribute.type === "MONEY"
																			|| attribute.type === "SMALLSERIAL"
																			|| attribute.type === "BIGSERIAL") 
																	&& 
																<Grid key={attribute.name} item xs={12} sm={6}>
																	<Grid container spacing={24}>
																		<Grid key={attribute.name + "From"} item xs={12} sm={6}>
																			<TextField 
																					inputRef={this.basicFilterRefs[attribute.name + "From"]}
																					label={t('e.' + this.props.entity + '.a.' + attribute.name) + " (Desde)"} 
																					fullWidth
																					inputProps={{min: attribute.min, max: attribute.max, step: attribute.step}}
																					InputLabelProps={{shrink: true}}
																					type="number"
																			/>
																		</Grid>
																		<Grid key={attribute.name + "To"} item xs={12} sm={6}>
																			<TextField 
																					inputRef={this.basicFilterRefs[attribute.name + "To"]}
																					label={t('e.' + this.props.entity + '.a.' + attribute.name) + " (Hasta)"} 
																					fullWidth
																					inputProps={{min: attribute.min, max: attribute.max, step: attribute.step}}
																					InputLabelProps={{shrink: true}}
																					type="number"
																			/>
																		</Grid>
																	</Grid>
																</Grid>
														) 
														||
														
														// Campos de tipo fecha con un único valor
														(
															!attribute.array 
																	&& (attribute.type === "DATE" || attribute.type === "TIMESTAMP" || attribute.type === "TIME") 
																	&& 
																<Grid key={attribute.name} item xs={12} sm={6}>
																	<Grid container spacing={24}>
																		<Grid key={attribute.name + "From"} item xs={12} sm={6}>
																			<TextField 
																					inputRef={this.basicFilterRefs[attribute.name + "From"]}
																					label={t('e.' + this.props.entity + '.a.' + attribute.name) + " (Desde)"} 
																					fullWidth
																					InputLabelProps={{shrink: true}}
																					type={(attribute.type === "DATE" ? "date" : (attribute.type === "TIME" ? "time" : "datetime-local"))}
																			/>
																		</Grid>
																		<Grid key={attribute.name + "To"} item xs={12} sm={6}>
																			<TextField 
																					inputRef={this.basicFilterRefs[attribute.name + "To"]}
																					label={t('e.' + this.props.entity + '.a.' + attribute.name) + " (Hasta)"} 
																					fullWidth
																					InputLabelProps={{shrink: true}}
																					type={(attribute.type === "DATE" ? "date" : (attribute.type === "TIME" ? "time" : "datetime-local"))}
																			/>
																		</Grid>
																	</Grid>
																</Grid>
														)
													)
												)
											)
										}
										</Grid>
									</Paper>
								)
							}
							
							<Paper square className={classes.root}>
							{
								(this.props.context.state[this.props.entity].mode === 'table' 
										&& ((this.state.data !== null && this.state.data.length > 0 
										&& 
									<Table className={classes.table}>
										<TableHead>
											<TableRow className={classes.tableHeader}>
												{
													(model.super || model.entities[this.props.entity].privileges["DELETE"] !== undefined)
															&&
														<TableCell padding="checkbox" key="checkbox" className={classes.checkbox}>
															<Checkbox
																	indeterminate={selectedItems.length !== 0
																			&& selectedItems.length !== this.state.data.length}
																	checked={selectedItems.length === this.state.data.length}
																	onChange={(event, checked) => this.handleSelectAllClick(event, checked)}
															/>
														</TableCell>
												}
												{
													this.state.intelligenceEnabled
															&&
														<TableCell 
																className={classes.intelligenceCell}>
															{this.props.t('prediction')}
														</TableCell>
												}
												{
													this.state.attributes
															.filter(attribute => 
																	(attribute.referenceAttributeName == null && entityLocalModel.attributes[attribute.name] != null && (entityLocalModel.attributes[attribute.name].list === undefined || entityLocalModel.attributes[attribute.name].list))
																	|| (attribute.referenceAttributeName != null && entityLocalModel.attributes[attribute.referenceAttributeName] != null && (entityLocalModel.attributes[attribute.referenceAttributeName].list === undefined || entityLocalModel.attributes[attribute.referenceAttributeName].list))
															)
															.map(attribute =>
														(
															attribute.referenceAttributeName === undefined 
																	&& !attribute.array
																	&& (attribute.type === "TEXT"
																			|| attribute.type === "BOOLEAN"
																			|| attribute.type === "DATE"
																			|| attribute.type === "TIMESTAMP"
																			|| attribute.type === "VARCHAR"
																			|| attribute.type === "CHAR"
																			|| attribute.type === "TIME"
																			|| attribute.type === "INTERVAL"
																			|| attribute.type === "TIMESTAMP_WITH_TIME_ZONE"
																			|| attribute.type === "TIME_WITH_TIME_ZONE"
																			|| attribute.type === "INTEGER"
																			|| attribute.type === "SERIAL"
																			|| attribute.type === "SMALLINT"
																			|| attribute.type === "BIGINT"
																			|| attribute.type === "SMALLSERIAL"
																			|| attribute.type === "BIGSERIAL"
																			|| attribute.type === "DECIMAL"
																			|| attribute.type === "MONEY"
																			|| attribute.type === "DOUBLE_PRECISION"
																			|| attribute.type === "REAL")
																	&& 
																<TableCell 
																		key={attribute.name} 
																		align={((attribute.type === 'INTEGER' || attribute.type === 'DECIMAL' || attribute.type === 'MONEY' || attribute.type === 'SERIAL' || attribute.type === 'SMALLINT' || attribute.type === 'BIGINT' || attribute.type === 'DOUBLE_PRECISION' || attribute.type === 'REAL' || attribute.type === 'SMALLSERIAL' || attribute.type === 'BIGSERIAL') && !attribute.referenceAttributeName ? "right" : (attribute.type == "BOOLEAN" ? "center" : "left"))}
																		sortDirection={orderBy === attribute.name ? orderDirection : false}>
																	<TableSortLabel
																			active={orderBy === attribute.name}
																			direction={orderDirection}
																			onClick={(event) => this.handleSortClick(event, attribute.name)}>
																		{t('e.' + this.props.entity + '.a.' + attribute.name)}
																	</TableSortLabel>
																</TableCell>
														) 
														||
														<TableCell 
																align={((attribute.type === 'INTEGER' || attribute.type === 'DECIMAL' || attribute.type === 'MONEY' || attribute.type === 'SERIAL' || attribute.type === 'SMALLINT' || attribute.type === 'BIGINT' || attribute.type === 'DOUBLE_PRECISION' || attribute.type === 'REAL' || attribute.type === 'SMALLSERIAL' || attribute.type === 'BIGSERIAL') && !attribute.referenceAttributeName ? "right" : (attribute.type == "BOOLEAN" ? "center" : "left"))}
																key={attribute.referenceAttributeName || attribute.name}>
															{t('e.' + this.props.entity + '.a.' + attribute.name)}
														</TableCell>
													)
												}
											</TableRow>
										</TableHead>
										<TableBody>
											{
												this.state.data.map(item => {
													
													const isSelected = item._selected != null && item._selected;
													return (
														<TableRow
																hover
																onClick={event => this.handleRowClick(event, item)}
																role="checkbox"
																tabIndex={-1}
																key={item[this.state.keyAttribute.name]}
																className={classes.tableRow}>
															{
																(model.super || model.entities[this.props.entity].privileges["DELETE"] !== undefined)
																		&&
																	<TableCell padding="checkbox" key="checkbbox">
																		<Checkbox 
																				checked={isSelected} 
																				onClick={event => this.handleCheckboxClick(event, item)}
																		/>
																	</TableCell>
															}
															{
																this.state.intelligenceEnabled
																		&&
																	<TableCell className={classes.intelligenceCell}>-</TableCell>
															}
															{
																this.state.attributes
																		.filter(attribute => 
																				(attribute.referenceAttributeName == null && entityLocalModel.attributes[attribute.name] != null && (entityLocalModel.attributes[attribute.name].list === undefined || entityLocalModel.attributes[attribute.name].list))
																				|| (attribute.referenceAttributeName != null && entityLocalModel.attributes[attribute.referenceAttributeName] != null && (entityLocalModel.attributes[attribute.referenceAttributeName].list === undefined || entityLocalModel.attributes[attribute.referenceAttributeName].list))
																		)
																		.map(attribute => (
																	attribute.referenceAttributeName === undefined 
																			&& 
																		<TableCell 
																				onClick={event => this.handleCellClick(event, item, attribute, entityModel, entityLocalModel)}
																				key={attribute.name} 
																				align={(attribute.type === 'INTEGER' || attribute.type === 'DECIMAL' || attribute.type === 'MONEY' || attribute.type === 'SERIAL' || attribute.type === 'SMALLINT' || attribute.type === 'BIGINT' || attribute.type === 'DOUBLE_PRECISION' || attribute.type === 'REAL' || attribute.type === 'SMALLSERIAL' || attribute.type === 'BIGSERIAL' ? "right" : (attribute.type == "BOOLEAN" ? "center" : "left"))}>
																			{
																				(
																					attribute.array 
																							&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
																							&&
																						<div>{item[attribute.name] == null ? "" : item[attribute.name].join(", ")}</div>
																				)
																				||
																				(
																					!attribute.array
																							&& attribute.enumType != null
																							&& item[attribute.name] != null
																							&&
																						<div>{t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.v.' + item[attribute.name])}</div>
																				)
																				||
																				(
																					!attribute.array
																							&& attribute.type === 'BOOLEAN'
																							&&
																						<Checkbox disabled checked={item[attribute.name]}></Checkbox>
																				)
																				||
																				(
																					!attribute.array
																							&& attribute.type === 'DATE'
																							&&
																						<div>{(item[attribute.name] == null ? "" : new Date(item[attribute.name]).toLocaleDateString())}</div>
																						/* Ojo con la fecha 02/02/0002, que JavaScript "se vuelve loco", aunque claro, hace 2000 años, ummm, igual es correcto */
																				)
																				||
																				(
																					!attribute.array
																							&& attribute.type === 'TIMESTAMP'
																							&&
																						<div>{(item[attribute.name] == null ? "" : new Date(item[attribute.name]).toLocaleString())}</div>
																				)
																				||
																				(
																					entityLocalModel.attributes[attribute.name].type == "DOCUMENT"
																						&& (
																							!attribute.array
																									&& item[attribute.name] != null 
																									&& item[attribute.name].thumbnail != null 
																									&& 
																								<img style={{maxHeight: "48px", maxWidth: "96px"}} src={(item[attribute.name] == null ? "" : item[attribute.name].thumbnail)}/>
																							|| 
																								<div>{(item[attribute.name] == null ? "" : item[attribute.name].name)}</div>
																						)
																				)
																				||
																				(
																					!attribute.array
																							&& (attribute.type === 'INTEGER' || attribute.type == 'SERIAL' || attribute.type == 'SMALLINT' || attribute.type == 'BIGINT' || attribute.type == 'SMALLSERIAL' || attribute.type == 'BIGSERIAL' || attribute.type == 'DECIMAL' || attribute.type == 'MONEY' || attribute.type == 'DOUBLE_PRECISION' || attribute.type == 'REAL')
																							&&
																						(entityLocalModel.attributes[attribute.name].step == null 
																								&& <div>{(item[attribute.name] == null ? "" : (entityLocalModel.attributes[attribute.name].prefix == null ? "" : entityLocalModel.attributes[attribute.name].prefix + " ") + new Number(item[attribute.name]).toLocaleString(navigator.language.replace("es", "de")) + (entityLocalModel.attributes[attribute.name].suffix == null ? "" : " " + entityLocalModel.attributes[attribute.name].suffix))}</div>
																								|| <div>{(item[attribute.name] == null ? "" : (entityLocalModel.attributes[attribute.name].prefix == null ? "" : entityLocalModel.attributes[attribute.name].prefix + " ") + new Number(item[attribute.name]).toLocaleString(navigator.language.replace("es", "de"), {minimumFractionDigits: (Math.floor(entityLocalModel.attributes[attribute.name].step) == entityLocalModel.attributes[attribute.name].step ? 0 : entityLocalModel.attributes[attribute.name].step.toString().split(".")[1].length), maximumFractionDigits: (Math.floor(entityLocalModel.attributes[attribute.name].step) == entityLocalModel.attributes[attribute.name].step ? 0 : entityLocalModel.attributes[attribute.name].step.toString().split(".")[1].length)}) + (entityLocalModel.attributes[attribute.name].suffix == null ? "" : " " + entityLocalModel.attributes[attribute.name].suffix))}</div>
																						)
																				)
																				||
																				<div>{item[attribute.name]}</div>
																			}
																		</TableCell>
																	) 
																	|| 
																	(
																		<TableCell key={attribute.referenceAttributeName}>
																			{
																				(
																					item[attribute.referenceAttributeName] != null 
																							&& 
																						// Le pongo el href, aunque no lo use, al menos para evitar el warning. Por otra parte, tampoco funcionaría porque recarga...
																						<a href={"not-used-but-required"} className={classes.anchor} onClick={event => this.handleAnchorClick(event, item, attribute)}>
																							{
																								item[attribute.referenceAttributeName] === null ? "" : this.getLabel(item[attribute.referenceAttributeName], model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName])
																							}
																						</a>
																				)
																			}
																		</TableCell>
																	)
																)
															}
														</TableRow>
													);
												})
											}
										</TableBody>
									</Table>
										) || (
									<Typography variant="subtitle1" className={classes.noData} color="inherit" noWrap>{t('noData')}</Typography>
										))
								)
								
								|| (this.props.context.state[this.props.entity].mode === 'calendar' 
										&& 
									<div className={classes.calendar}>
							    		<BigCalendar
							    				date={(this.state.data.filter(item => localAttributes.filter(attribute => attribute.eventStart).length > 0 && item[localAttributes.filter(attribute => attribute.eventStart)[0].name] != null).length > 0 ? new Date(this.state.data[0][localAttributes.filter(attribute => attribute.eventStart)[0].name]) : new Date())}
							    				className={classes.bigCalendar}
							    				culture={t('calendarCulture')}
							    				localizer={localizer}
							    				events={this.state.data.filter(item => localAttributes.filter(attribute => attribute.eventStart).length > 0 && item[localAttributes.filter(attribute => attribute.eventStart)[0].name] != null)}
							    				defaultView={(localAttributes.filter(attribute => attribute.eventStart)[0].type == "TIMESTAMP" ? "week" : "month")}
							    				views={["month", "week", "day"]}
							    				titleAccessor={localAttributes.filter(attribute => attribute.label)[0].name}
							    				allDayAccessor={(item) => (localAttributes.filter(attribute => attribute.eventStart)[0].type == "DATE")}
							    				startAccessor={(item) => (localAttributes.filter(attribute => attribute.eventStart).length > 0 ? new Date(item[localAttributes.filter(attribute => attribute.eventStart)[0].name]) : null)}
							    				endAccessor={(item) => (localAttributes.filter(attribute => attribute.eventEnd).length > 0 ? new Date(item[localAttributes.filter(attribute => attribute.eventEnd)[0].name]) : (localAttributes.filter(attribute => attribute.eventStart).length > 0 ? new Date(item[localAttributes.filter(attribute => attribute.eventStart)[0].name]) : null))}
							    				onNavigate={() => null}
							    				onSelectEvent={(item) => this.go("/admin/" + this.props.entity + '/' + item[this.state.keyAttribute.name] + "/view", true, this.props.entity)}
							    				showMultiDayTimes={false}
							    				messages={{
							    					date: t('calendarDate'),
							    					time: t('calendarTime'),
							    					event: t('calendarEvent'),
							    					allDay: t('calendarAllDay'),
							    					week: t('calendarWeek'),
							    					work_week: t('calendarWorkWeek'),
							    					day: t('calendarDay'),
							    					month: t('calendarMonth'),
							    					previous: t('calendarPrevious'),
							    					next: t('calendarNext'),
							    					yesterday: t('calendarYesterday'),
							    					tomorrow: t('calendarTomorrow'),
							    					today: t('calendarToday'),
							    					agenda: t('calendarAgenda'),
							    				}}
							    		/>
							    	</div>
							    )
								
								|| (this.props.context.state[this.props.entity].mode === 'map' 
										&& 
									<Map zoom={13}
											minZoom={2}
											maxZoom={19}
											center={[this.state.latitude, this.state.longitude]}
											maxBounds={[[-90, -180], [90, 180]]}
											zoomControl={true}
									>
										<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"/>
										
										{/*
										<ImageOverlay 
												url="http://localhost:3000/008-Colón-27-Hogar-PB.png" 
												bounds={[[-90, -180], [90, 180]]}
												attribution={"El Corte Inglés"}
										/>
										*/}
										
										{
											this.state.data.map(item => item[mapAttribute.name] != null && (
												<Marker onclick={event => this.handleRowClick(event.originalEvent, item)} key={item[mapAttribute.name]} position={[item[mapAttribute.name].substr(1, item[mapAttribute.name].length - 2).split(",")[0], item[mapAttribute.name].substr(1, item[mapAttribute.name].length - 2).split(",")[1]]}>
												</Marker>
											))
										}
									</Map>
								)
								
								|| (this.props.context.state[this.props.entity].mode === 'gallery' 
										&& ((this.state.data.filter(item => item[galleryAttribute.name] != null).length > 0 
										&& 
									
									<div style={{padding: 20}}>
										<Grid 
												spacing={8}
												container 
												direction="row"
												justify="flex-start"
												alignItems="flex-end"
												style={{listStyle: "none"}}
										>
											{
												this.state.data.map(item => item[galleryAttribute.name] != null && 
													<Grid key={item[this.state.keyAttribute.name]} item style={{padding: 10}}>
														<GridListTile onClick={() => this.go("/admin/" + this.props.entity + '/' + item[this.state.keyAttribute.name] + "/view", true, this.props.entity)} style={{cursor: "pointer"}}>
															<img style={{maxWidth: "640px", maxHeight: "360px"}} src={item[galleryAttribute.name].thumbnail} alt={this.getLabel(item, entityModel, entityLocalModel)}/>
															<GridListTileBar
																	title={this.getLabel(item, entityModel, entityLocalModel)}
																	subtitle={<span>{item[galleryAttribute.name].name}</span>}
															/>
														</GridListTile>
													</Grid>
												)
											}
										</Grid>
									</div>
								) || (
									<Typography variant="subtitle1" className={classes.noData} color="inherit" noWrap>{t('noDataWithImage')}</Typography>
								)))
								
								|| (this.props.context.state[this.props.entity].mode === 'diagram' 
									&& 
										<BlockPageScroll>
											<CanvasWidget className={classes.canvasWidget} engine={engine}/>
										</BlockPageScroll>
								)
							}
							</Paper>
						</Grid>
						
						{
							questions.length > 0 
								&&
							<Grid item xs={12} sm={4} lg={3} xl={2}>
								{
									questions.sort((a, b) => (a.order == null ? Infinity : a.order) - (b.order == null ? Infinity : b.order)).map(question => (
										this.state.questions != null
												&& this.state.questions[question.name] != null
												&&
											<div key={question.name}>
												<Paper square>
													<Toolbar className={classes.toolbar}>
														<Grid container spacing={24} justify="flex-start" alignItems="center">
															<Grid item xs sm>
																<Typography variant="h6" color="inherit" noWrap>{t('e.' + this.props.entity + '.questions.' + question.name)}</Typography>
															</Grid>
															
															<Grid item xs sm style={{textAlign: "end", whiteSpace: "nowrap"}}>
																<Tooltip title={t('fullscreen')} disableFocusListener>
																	<div>
																		<IconButton
																				aria-label={t('fullscreen')} 
																				onClick={event => document.getElementById(question.name).requestFullscreen()}>
																			<FullscreenIcon/>
																		</IconButton>
																	</div>
																</Tooltip>
															</Grid>
														</Grid>
													</Toolbar>
												</Paper>
												<Paper square className={classes.paperBottom}>
													<Grid item>
														<iframe id={question.name} allowFullScreen scrolling="no" className={classes.iframe} src={this.props.context.baseUrl + "/dashboards/embed/question/" + this.state.questions[question.name].url + "#bordered=false&titled=false"}></iframe>
													</Grid>
												</Paper>
											</div>
									))
								}
							</Grid>
						}
					</Grid>
					
					{
						(!this.props.main && this.state.scrollEnabled
								&&
							<Paper square className={classes.root}>
								<Button
										className={classes.expandMoreButton}
										onClick={this.expandMore}>
									<ExpandMoreIcon/>
								</Button>
							</Paper>
						)
					}
					
					<Dialog style={{zIndex: 10000}}
							open={this.state && this.state.deleteConfirmationDialogOpened}
							onClose={event => this.setState({deleteConfirmationDialogOpened: false})}>
						<DialogTitle>{t('deleteConfirmation')}</DialogTitle>
						<DialogContent>
							<DialogContentText>{t('deleteQuestion') + " " + selectedItems.length + " " + t('selected') + "?"}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={event => this.setState({deleteConfirmationDialogOpened: false})}>{t('cancel')}</Button>
							&nbsp;
							<Button color="primary" onClick={this.handleDeleteDebounced}>{t('delete')}</Button>
						</DialogActions>
					</Dialog>
					<Dialog 
							scroll="paper"
							open={this.state.previewOpen} 
							onClose={this.onPreviewCloseDialog} 
							classes={{ paper: classes.previewDialogPaper }}>
						<DialogTitle>
							<IconButton aria-label="close" className={classes.closeButton} onClick={this.onPreviewCloseDialog}>
								<CloseIcon />
					        </IconButton>
						</DialogTitle>
						<DialogContent className={classes.dialogContent}>
							<DocViewer 
									className={classes.docViewer}
									pluginRenderers={DocViewerRenderers}
									documents={[{uri: this.state.previewUrl}]}
									config={{
										header: {
											disableHeader: true,
											disableFileName: true,
										}
									}}
							/>
						</DialogContent>
					</Dialog>
					
					<Snackbar
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							autoHideDuration={5000}
							onClose={event => this.setState({ messageOpened: false })}
							open={this.state && this.state.messageOpened}>
						<SnackbarContent
								className={this.state.messageError ? classes.snackbarError : classes.snackbar}
								message={<><span className={classes.message}>{this.state.messageError && <ErrorIcon className={classes.icon}/>}{this.state.message}</span></>}
						/>
					</Snackbar>
				</form>
			);
		}
		else if (this.state.messageError) {
			return (
			<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					autoHideDuration={5000}
					onClose={event => this.setState({ messageOpened: false })}
					open={this.state && this.state.messageOpened}>
				<SnackbarContent
						className={this.state.messageError ? classes.snackbarError : classes.snackbar}
						message={<><span className={classes.message}>{this.state.messageError && <ErrorIcon className={classes.icon}/>}{this.state.message}</span></>}
				/>
			</Snackbar>
			);
		}
		else {
			return null;
		}
	}
}

EntityList.propTypes = {
	context: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	entity: PropTypes.string.isRequired,
	mode: PropTypes.string, // "table", "calendar", "map", "gallery"
	filterAttribute: PropTypes.string,
	filterValue: PropTypes.string,
	disabled: PropTypes.bool.isRequired,
};

export default withStyles(styles)(withContext(withI18n()(EntityList)));
