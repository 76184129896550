import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { withContext } from './App';
import { withI18n } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import Dropzone from 'react-dropzone';
import Grid from '@material-ui/core/Grid';
import history from './history';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
	title: {
		flexGrow: 1,
	},
	root: {
		width: '100%',
		overflowX: 'auto',
		overflowY: 'auto',
		padding: theme.spacing.unit * 3,
	},
	toolbar: {
	},
	snackbar: {
	},
	snackbarError: {
		backgroundColor: theme.palette.error.dark,
	},
	deleteDatabase: {
		color: "#ff0000",
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		fontSize: 20,
		marginRight: theme.spacing.unit,
	},
	
	'@keyframes progress': {
		'0%': {
			backgroundPosition: '0 0',
		},
		'100%': {
			backgroundPosition: '-70px 0',
		},
	},
	dropZone: {
		position: 'relative',
		width: '100%',
		minHeight: '100px',
		backgroundColor: '#F0F0F0',
		border: 'dashed',
		borderColor: '#C8C8C8',
		cursor: 'pointer',
		boxSizing: 'border-box',
	},
	stripes: {
		border: 'solid',
		backgroundImage: 'repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px)',
		animation: 'progress 2s linear infinite !important',
		backgroundSize: '150% 100%',
	},
	rejectStripes: {
		border: 'solid',
		backgroundImage: 'repeating-linear-gradient(-45deg, #fc8785, #fc8785 25px, #f4231f 25px, #f4231f 50px)',
		animation: 'progress 2s linear infinite !important',
		backgroundSize: '150% 100%',
	},
	dropzoneTextStyle:{
		paddingLeft: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
		paddingTop: theme.spacing.unit,
		textAlign: 'center',
	},
	dropzoneParagraph:{
		top: 0,
	},
});

class AdminTools extends Component {
	
	constructor(props) {
		super(props);
		// console.log(">> AdminTools.constructor");
		
		this.state = {
			updateFQDNDialogOpened: false,
			updateEmbeddingSecretKeyDialogOpened: false,
			restoreConfirmationDialogOpened: false,
			importExcelConfirmationDialogOpened: false,
			deleteDatabaseConfirmationDialogOpened: false,
			fqdn: "",
			embeddingSecretKey: "",
		};
		
		this.handleDownloadBackupClick = this.handleDownloadBackupClick.bind(this);
		this.handleExcelExportClick = this.handleExcelExportClick.bind(this);
		this.handleDeleteDatabaseClick = this.handleDeleteDatabaseClick.bind(this);
		this.handleChangeFQDNClick = this.handleChangeFQDNClick.bind(this);
		this.handleChangeEmbeddingSecretKeyClick = this.handleChangeEmbeddingSecretKeyClick.bind(this);
		this.handleFileDrop = this.handleFileDrop.bind(this);
		this.handleFileDropImportExcel = this.handleFileDropImportExcel.bind(this);
		this.handleRestore = this.handleRestore.bind(this);
		this.handleChangeFQDN = this.handleChangeFQDN.bind(this);
		this.handleChangeEmbeddingSecretKey = this.handleChangeEmbeddingSecretKey.bind(this);
		this.handleImportExcel = this.handleImportExcel.bind(this);
		this.handleDeleteDatabase = this.handleDeleteDatabase.bind(this);
	}
	
	componentDidMount() {
		this.props.context.handleDrawerClose();
		
		const variables = {
			authorization: this.props.context.accessToken
		};

		let request = JSON.stringify({query: "{version: getPlatformVersion fqdn: getFQDN {fqdn ipAddress} size: getDataVolumeSize {size used available} cpus: getCPUCount metabase: Models_MetabaseList { embeddingSecretKey }}", variables: variables});
		fetch(this.props.context.baseUrl + "/graphql", {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			if (json.errors != null) {
				document.location = '/login';
			}
			else {
				this.setState({
					version: json.data.version,
					fqdn: json.data.fqdn.fqdn,
					embeddingSecretKey: json.data.metabase[0].embeddingSecretKey,
					ipAddress: json.data.fqdn.ipAddress,
					dataVolumeSize: json.data.size.size,
					dataVolumeUsed: json.data.size.used,
					dataVolumeAvailable: json.data.size.available,
					cpus: json.data.cpus,
				});
			}
		});
	}
	
	// Event handlers
	handleDownloadBackupClick(event) {
		this.props.context.showActivityIndicator();
		let filename = null;
		fetch(this.props.context.baseUrl + "/backup", {
			method: "GET",
			headers: {
				"Authorization": "Basic " + this.props.context.accessToken,
			},
		})
		.then(response => {
			filename = response.headers.get("Content-Disposition").split(";")[1].split("=")[1].slice(1, -1);
			return response.blob();
		})
		.then(blob => {
			var url = window.URL.createObjectURL(blob);
			var a = document.createElement('a');
			a.href = url;
			a.download = filename;
			document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
			a.click();    
			a.remove();  //afterwards we remove the element again         
			this.props.context.hideActivityIndicator();
		});
	}

	handleExcelExportClick(event) {
		this.props.context.showActivityIndicator();
		let filename = null;
		
		fetch(this.props.context.baseUrl + "/model-export", {
			method: "GET",
			headers: {
				"Authorization": "Basic " + this.props.context.accessToken,
			},
		})
		.then(response => {
			filename = response.headers.get("Content-Disposition").split(";")[1].split("=")[1].slice(1, -1);
			return response.blob();
		})
		.then(blob => {
			var url = window.URL.createObjectURL(blob);
			var a = document.createElement('a');
			a.href = url;
			a.download = filename;
			document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
			a.click();    
			a.remove();  //afterwards we remove the element again         
			this.props.context.hideActivityIndicator();
		});
	}

	handleChangeFQDNClick(event) {
		this.setState({
			updateFQDNDialogOpened: true,
		});
	}

	handleChangeEmbeddingSecretKeyClick(event) {
		this.setState({
			updateEmbeddingSecretKeyDialogOpened: true,
		});
	}
	
	handleDeleteDatabaseClick(event) {
		this.setState({
			deleteDatabaseConfirmationDialogOpened: true,
		});
	}

	handleChangeFQDN() {
		this.props.context.showActivityIndicator();
		
		const variables = {
			authorization: this.props.context.accessToken
		};
		
		let request = JSON.stringify({query: "{ setFQDN(fqdn: \"" + this.state.fqdn + "\") }", variables: variables});
		fetch(this.props.context.baseUrl + "/graphql", {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			if (json.errors != null) {
				document.location = '/login';
			}
			else {
				document.location = "https://" + this.state.fqdn;
			}
		});
	}
	
	handleChangeEmbeddingSecretKey() {
		this.props.context.showActivityIndicator();
		
		const variables = {
			authorization: this.props.context.accessToken
		};
		
		let request = JSON.stringify({query: "{ setMetabaseConfiguration(embeddingSecretKey: \"" + this.state.embeddingSecretKey + "\") }", variables: variables});
		fetch(this.props.context.baseUrl + "/graphql", {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			this.setState({
				updateEmbeddingSecretKeyDialogOpened: false,
				message: this.props.t('updateEmbeddingSecretKeySuccess'),
				messageError: false,
				messageOpened: true,
				deleteDatabaseConfirmationDialogOpened: false,
			});
			this.props.context.hideActivityIndicator();
		});
	}
	
	handleFileDrop(files) {
		if (files != null && files.length == 1) {
			this.setState({
				file: files[0],
				restoreConfirmationDialogOpened: true,
			});
		}
		else {
			this.setState({
				message: this.props.t('restoreOnlyOneFile'),
				messageError: true,
				messageOpened: true,
			});
		}
	}
	
	handleFileDropImportExcel(files) {
		if (files != null && files.length == 1) {
			this.setState({
				file: files[0],
				importExcelConfirmationDialogOpened: true,
			});
		}
		else {
			this.setState({
				message: this.props.t('restoreOnlyOneFile'),
				messageError: true,
				messageOpened: true,
			});
		}
	}
	
	handleDeleteDatabase() {
		this.props.context.showActivityIndicator();
		
		var request = new XMLHttpRequest();
		
		let thisInstance = this;
		
		request.onloadend = function() {
			thisInstance.setState({
				message: thisInstance.props.t('deleteDatabaseSuccess'),
				messageError: false,
				messageOpened: true,
				deleteDatabaseConfirmationDialogOpened: false,
			});
			thisInstance.props.context.hideActivityIndicator();
			document.location = "/login";
		}
		
		request.open("POST", this.props.context.baseUrl + "/deleteDatabase");
		request.setRequestHeader("Authorization", "Basic " + this.props.context.accessToken);
		
		request.send(this.state.file);
	}
	
	handleRestore() {
		let thisInstance = this;
		
		this.props.context.showActivityIndicator();
		
		var request = new XMLHttpRequest();
		
		request.upload.onprogress = function(event) {
			if (event.lengthComputable) {
				thisInstance.setState({
					message: Math.round(100 * event.loaded / event.total) + "%",
					messageError: false,
					messageOpened: true,
				});
			}
		};
		
		request.onloadend = function() {
			thisInstance.setState({
				message: thisInstance.props.t('restoreSuccess'),
				messageError: false,
				messageOpened: true,
				restoreConfirmationDialogOpened: false,
			});
			thisInstance.props.context.hideActivityIndicator();
			document.location = "/login";
		}
		
		request.open("POST", this.props.context.baseUrl + "/restore");
		request.setRequestHeader("Authorization", "Basic " + this.props.context.accessToken);
		
		request.send(this.state.file);
	}
	
	handleImportExcel() {
		let thisInstance = this;
		
		this.props.context.showActivityIndicator();
		
		var request = new XMLHttpRequest();
		
		request.upload.onprogress = function(event) {
			if (event.lengthComputable) {
				thisInstance.setState({
					message: Math.round(100 * event.loaded / event.total) + "%",
					messageError: false,
					messageOpened: true,
				});
			}
		};
		
		request.onloadend = function() {
			thisInstance.props.context.hideActivityIndicator();
			
			if (request.status == 200) {
				thisInstance.setState({
					message: thisInstance.props.t('importSuccess'),
					messageError: false,
					messageOpened: true,
					importExcelConfirmationDialogOpened: false,
				}, () => {
					document.location = "/login";
				});
			}
			else if (request.responseText != null) {
				let error = JSON.parse(request.responseText);
				thisInstance.setState({
					message: error.exceptionMessage + (error.entitySchema != null ? " (" + error.entitySchema + "." + error.entityName + (error.attributeName != null ? "." + error.attributeName : "") + ")" : "") + (error.lineNumber != null ? " [" + error.lineNumber + "]": ""),
					messageError: true,
					messageOpened: true,
					importExcelConfirmationDialogOpened: false,
				});
			}
			else {
				thisInstance.setState({
					message: thisInstance.props.t('error'),
					messageError: true,
					messageOpened: true,
					importExcelConfirmationDialogOpened: false,
				});
			}
		}
		
		request.open("POST", this.props.context.baseUrl + "/model-import");
		request.setRequestHeader("Authorization", "Basic " + this.props.context.accessToken);
		
		request.send(this.state.file);
	}
	
	// Render
	
	render() {
		// console.log(">> AdminTools.render");
		
		const { classes, t } = this.props;
		
		let model = this.props.context.model;
		
		return model !== null && (
			<>
				<Paper square>
					<Toolbar className={classes.toolbar}>
						<Typography variant="h6" className={classes.title} color="inherit" noWrap>{t('admin')}</Typography>
					</Toolbar>
				</Paper>
				<Paper square className={classes.root}>
					<Grid container spacing={24}>
						{/*
						<Grid item xs={12}>
							<Typography variant="subtitle2" 
									color="inherit" 
									noWrap>
								{t('backupBackup')}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Button variant="outlined" color="primary" onClick={this.handleDownloadBackupClick}>{t('downloadBackup')}</Button>
						</Grid>
						
						<Grid item xs={12}>
							<Typography variant="subtitle2" 
									color="inherit" 
									noWrap>
								{t('backupRestore')}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Dropzone
									accept={"application/sql"}
									className={classes.dropZone}
									acceptClassName={classes.stripes}
									rejectClassName={classes.rejectStripes}
									onDrop={this.handleFileDrop}
							>
								<div className={classes.dropzoneTextStyle}>
									<Typography variant="button" 
										className={classes.dropzoneParagraph}
										color="inherit"
										noWrap
									>
										{this.props.t('uploadBackup')}
									</Typography>
								</div>
							</Dropzone>
						</Grid>
						*/}
						
						<Grid item xs={12}>
							<Typography 
									style={{paddingBottom: 8}}
									variant="subtitle2" 
									color="inherit" 
									noWrap>
								{t('excelExport')}
							</Typography>
							<Button variant="outlined" color="primary" onClick={this.handleExcelExportClick}>{t('downloadExcel')}</Button>
						</Grid>
						
						<Grid item xs={12}>
							<Typography 
									style={{paddingBottom: 8}}
									variant="subtitle2" 
									color="inherit" 
									noWrap>
								{t('excelImport')}
							</Typography>
							<Dropzone
									accept={"application/vnd.ms-excel,image/*"}
									className={classes.dropZone}
									acceptClassName={classes.stripes}
									rejectClassName={classes.rejectStripes}
									onDrop={this.handleFileDropImportExcel}
							>
								<div className={classes.dropzoneTextStyle}>
									<Typography variant="button" 
										className={classes.dropzoneParagraph}
										color="inherit"
										noWrap
									>
										{this.props.t('uploadExcel')}
									</Typography>
								</div>
							</Dropzone>
						</Grid>
						
						<Grid item xs={12}>
							<Typography 
									variant="subtitle2" 
									color="inherit" 
									noWrap>
								{t('updateFQDN')}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<TextField
									label={t('fqdn')} 
									fullWidth
									inputProps={{
										maxLength: 200, 
									}}
									InputLabelProps={{shrink: true}}
									value={this.state.fqdn}
									onChange={event => this.setState({fqdn: event.target.value})}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button variant="outlined" color="primary" onClick={this.handleChangeFQDNClick}>{t('changeFQDN')}</Button>
						</Grid>
							
						<Grid item xs={12}>
							<Typography 
									variant="subtitle2" 
									color="inherit" 
									noWrap>
								{t('updateEmbeddingSecretKey')}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<TextField
									label={t('embeddingSecretKey')} 
									fullWidth
									inputProps={{
										maxLength: 200, 
									}}
									InputLabelProps={{shrink: true}}
									value={this.state.embeddingSecretKey}
									onChange={event => this.setState({embeddingSecretKey: event.target.value})}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button variant="outlined" color="primary" onClick={this.handleChangeEmbeddingSecretKeyClick}>{t('changeEmbeddingSecretKey')}</Button>
						</Grid>
							
						<Grid item xs={12}>
							<Typography 
									style={{paddingBottom: 8}}
									variant="subtitle2" 
									color="inherit" 
									noWrap>
								{t('deleteDatabase')}
							</Typography>
							<Button variant="outlined" color="secondary" className={classes.deleteDatabase} onClick={this.handleDeleteDatabaseClick}>{t('deleteRestoreToDefault')}</Button>
						</Grid>
						
						<Grid item xs={12}>
							<Typography 
									variant="subtitle2" 
									color="inherit" 
									noWrap>
								{t('version')}
							</Typography>
							<Typography variant="subtitle1" 
									color="inherit" 
									noWrap>
								{this.state.version}
							</Typography>
							<Typography variant="subtitle1" 
									color="inherit" 
									noWrap>
								{t('edition') + ": " + model.edition}
							</Typography>
						</Grid>
						
						<Grid item xs={12}>
							<Typography 
									variant="subtitle2" 
									color="inherit" 
									noWrap>
								{t('cpus')}
							</Typography>
							<Typography variant="subtitle1" 
									color="inherit" 
									noWrap>
								{(this.state.cpus != null ? this.state.cpus : t('unknown'))}
							</Typography>
						</Grid>
						
						<Grid item xs={12}>
							<Typography 
									variant="subtitle2" 
									color="inherit" 
									noWrap>
								{t('dataVolumeSizes')}
							</Typography>
							<Typography variant="subtitle1" 
									color="inherit" 
									noWrap>
								{t('dataVolumeSize') + ": " + (this.state.dataVolumeSize != null ? this.state.dataVolumeSize : t('unknown')) + ", " + t('dataVolumeUsed') + ": " + (this.state.dataVolumeUsed != null ? this.state.dataVolumeUsed : t('unknown')) + ", " + t('dataVolumeAvailable') + ": " + (this.state.dataVolumeAvailable != null ? this.state.dataVolumeAvailable : t('unknown'))		}
							</Typography>
						</Grid>
					</Grid>
				</Paper>
				<Dialog style={{zIndex: 10000}}
						open={this.state && this.state.updateFQDNDialogOpened}
						onClose={event => this.setState({updateFQDNDialogOpened: false})}>
					<DialogTitle>{t('updateFQDN')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('confirmFQDNChange')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={event => this.setState({updateFQDNDialogOpened: false})}>{t('cancel')}</Button>
						&nbsp;
						<Button color="primary" onClick={this.handleChangeFQDN}>{t('changeFQDN')}</Button>
					</DialogActions>
				</Dialog>
				<Dialog style={{zIndex: 10000}}
						open={this.state && this.state.updateEmbeddingSecretKeyDialogOpened}
						onClose={event => this.setState({updateEmbeddingSecretKeyDialogOpened: false})}>
					<DialogTitle>{t('updateEmbeddingSecretKey')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('confirmEmbeddingSecretKeyChange')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={event => this.setState({updateEmbeddingSecretKeyDialogOpened: false})}>{t('cancel')}</Button>
						&nbsp;
						<Button color="primary" onClick={this.handleChangeEmbeddingSecretKey}>{t('changeEmbeddingSecretKey')}</Button>
					</DialogActions>
				</Dialog>
				<Dialog style={{zIndex: 10000}}
						open={this.state && this.state.restoreConfirmationDialogOpened}
						onClose={event => this.setState({restoreConfirmationDialogOpened: false})}>
					<DialogTitle>{t('restoreConfirmation')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('restoreQuestion')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={event => this.setState({restoreConfirmationDialogOpened: false})}>{t('cancel')}</Button>
						&nbsp;
						<Button color="primary" onClick={this.handleRestore}>{t('restore')}</Button>
					</DialogActions>
				</Dialog>
				<Dialog style={{zIndex: 10000}}
						open={this.state && this.state.importExcelConfirmationDialogOpened}
						onClose={event => this.setState({importExcelConfirmationDialogOpened: false})}>
					<DialogTitle>{t('importConfirmation')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('importQuestion')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={event => this.setState({importExcelConfirmationDialogOpened: false})}>{t('cancel')}</Button>
						&nbsp;
						<Button color="primary" onClick={this.handleImportExcel}>{t('import')}</Button>
					</DialogActions>
				</Dialog>
				<Dialog style={{zIndex: 10000}}
						open={this.state && this.state.deleteDatabaseConfirmationDialogOpened}
						onClose={event => this.setState({deleteDatabaseConfirmationDialogOpened: false})}>
					<DialogTitle>{t('deleteDatabaseConfirmation')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('deleteDatabaseQuestion')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={event => this.setState({deleteDatabaseConfirmationDialogOpened: false})}>{t('cancel')}</Button>
						&nbsp;
						<Button color="secondary" className={classes.deleteDatabase} onClick={this.handleDeleteDatabase}>{t('deleteDatabase')}</Button>
					</DialogActions>
				</Dialog>
				<Snackbar
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						autoHideDuration={5000}
						onClose={event => this.setState({ messageOpened: false })}
						open={this.state && this.state.messageOpened}>
					<SnackbarContent
							className={this.state.messageError ? classes.snackbarError : classes.snackbar}
							message={<><span className={classes.message}>{this.state.messageError && <ErrorIcon className={classes.icon}/>}{this.state.message}</span></>}
					/>
				</Snackbar>
			</>
		);
	}
}

AdminTools.propTypes = {
	context: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withContext(withI18n()(AdminTools)));
