import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { withContext } from './App';
import { withI18n } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';

const styles = theme => ({
	spacer: {
		flex: "1 1 100%",
	},
	title: {
		flex: "0 0 auto",
	},
	fab: {
		position: 'fixed',
		bottom: theme.spacing.unit * 2,
		right: theme.spacing.unit * 2,
	},
	root: {
		width: '100%',
		overflowX: 'auto',
		overflowY: 'auto',
	},
	toolbar: {
	},
	iframe: {
		width: '100%',
		border: "none",
		// overflow: hidden - Es imposible que un iframe muestre algo flotante sobre otro (sería un problema de seguridad)
		// Otra cosa es que se coja el contenido del iframe y se inyecte en un div, con javascript.
	},
	snackbar: {
	},
	snackbarError: {
		backgroundColor: theme.palette.error.dark,
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		fontSize: 20,
		marginRight: theme.spacing.unit,
	},
});

class DashboardTool extends Component {

	// Event handlers

	// Life cycle methods

	componentDidMount() {
		const query = '{DashboardURL(id: ' + this.props.id + ')}';
		
		const variables = {
    		authorization: this.props.context.accessToken
    	};
		let request = JSON.stringify({query: query, variables: variables});
		
		fetch(this.props.context.baseUrl + "/graphql", {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			if (json.errors != null && json.errors.length > 0) {
				this.setState({
					message: json.errors[0].message,
					messageError: true,
					messageOpened: true,
				});
			}
			else {
				this.setState((state, props) => ({
					dashboardUrl: json.data.DashboardURL
				}));
			}
		});
	}
	
	// Other methods
	
	// Render
	
	render() {
		const { classes, t } = this.props;
		
		return this.state != null && (
			<>
				<Paper square>
					<Toolbar className={classes.toolbar}>
						<Grid container spacing={24} justify="flex-start" alignItems="center">
							<Grid item xs sm>
								<Typography variant="h6" className={classes.title} color="inherit" noWrap>{t('dashboards.' + this.props.name)}</Typography>
							</Grid>
							
							<Grid item xs sm style={{textAlign: "end", whiteSpace: "nowrap"}}>
								<Tooltip title={t('fullscreen')} disableFocusListener>
									<div>
										<IconButton
												aria-label={t('fullscreen')} 
												onClick={event => document.getElementById("dashboard").requestFullscreen()}>
											<FullscreenIcon/>
										</IconButton>
									</div>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</Paper>
				
				<Paper square className={classes.root}>
					{
						this.state.dashboardUrl != null
								&&
							<iframe onLoad={event => event.currentTarget.style.height = "calc(100vh - 230px)"} id="dashboard" allowFullScreen className={classes.iframe} src={this.props.context.baseUrl + "/dashboards/embed/dashboard/" + this.state.dashboardUrl + "#bordered=false&titled=false"}></iframe>
					}
				</Paper>
				
				<Snackbar
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						autoHideDuration={5000}
						onClose={event => this.setState({ messageOpened: false })}
						open={this.state && this.state.messageOpened}>
					<SnackbarContent
							className={this.state.messageError ? classes.snackbarError : classes.snackbar}
							message={<><span className={classes.message}>{this.state.messageError && <ErrorIcon className={classes.icon}/>}{this.state.message}</span></>}
					/>
				</Snackbar>
			</>
		);
	}
}

DashboardTool.propTypes = {
	context: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
};

export default withStyles(styles)(withContext(withI18n()(DashboardTool)));
